import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import Api from "./serviceApi/index";
import reducers from "../reducers/index";
const middlewares = [thunk.withExtraArgument(Api)];
if (process.env.NODE_ENV !== "production") {
  middlewares.push(
    createLogger({
      colors: {
        title: () => "inherit",
        prevState: () => "red",
        action: () => "#03A9F4",
        nextState: () => "#4CAF50"
      }
    })
  );
}
const store = createStore(reducers, applyMiddleware(...middlewares));
export default store;
