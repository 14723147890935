export const createOrder = payload => (dispatch, getState, Api) => {
  return Api.post("order", payload, null, "v2").then(response => {
    // return Api.post("order", payload).then(response => {
    return response;
  });
};
export const getOrders = payload => (dispatch, getState, Api) => {
  let apiUrl = "users/orders?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.order_state) {
    apiUrl += `&order_state=${payload.order_state}`;
  }
  return Api.get(apiUrl, null, "v3").then(response => {
    return response;
  });
};
export const submitReview = payload => (dispatch, getState, Api) => {
  return Api.post("product/reviews", payload).then(response => {
    return response;
  });
};
export const cancelOrder = (payload, id) => (dispatch, getState, Api) => {
  return Api.put("users/orders/" + id + "/cancel", payload).then(response => {
    return response;
  });
};
export const getOrderDetail = orderId => (dispatch, getState, Api) => {
  return Api.get("users/orders/" + orderId, null, "v3").then(response => {
    return response;
  });
};
export const completeOrder = orderId => (dispatch, getState, Api) => {
  return Api.put("users/order/" + orderId + "/complete").then(response => {
    return response;
  });
};
export const submitRefundForm = (payload, id) => (dispatch, getState, Api) => {
  return Api.post("users/order/" + id + "/refund", payload).then(response => {
    return response;
  });
};

export const orderByJNE = (payload ) => (dispatch, getState, Api) => {
  return Api.post("orderJne", payload , null , 'v3' ).then(response => {
    return response;
  });
};
export const createInvoicePaypal = (order_id) => (dispatch, getState, Api) => {
  return Api.post(`users/orders/${ order_id }/invoicePaypal`, {} , null , 'v3' ).then(response => {
    return response;
  });
};

export const orderManualTransfer = (orderId) => (dispatch, getState, Api) => {
  return Api.put(`users/order/${orderId}/orderManualTransfer`, null , null , 'v3' ).then(response => {
    return response;
  });
};
export const getPaymentIntent = orderId => (dispatch, getState, Api) => {
  return Api.get("stripe/paymentIntent?order_id=" + orderId, null, "v3").then(response => {
    return response;
  });
};

export const addTrackingNumber = (order_id, waybill_number) => (dispatch, getState, Api) => {
  return Api.post(`users/orders/shipmentNumber/${order_id}`, { waybill_number } , null , 'v3' ).then(response => {
    return response;
  });
};
