import { PAGES } from "../actions/typesOfActions";
const intialState = {
    pages: [],
};
export default function (state = intialState, action) {
    switch (action.type) {
        case PAGES:
            return {
                ...state,
                pages: action.data
            };
        default:
            return state;
    }
}