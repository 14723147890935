import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import $ from "jquery";
import * as firebase from "firebase/app";
import "firebase/auth";
import { connect } from "react-redux";
import _ from "lodash";
import global from "../../../../global";
import {
  getUserProfile,
  logout,
  // userProfile,
  addToCart,
  handleProductSearch,
  getDevice  
} from "../../../../actions";
import "../HeaderBottomSection/HeaderBottomSection.scss";
import Login from "../../../Login";
import logo from "../../../../assets/img/logo.svg";
import find from "../../../../assets/img/find.svg";
import cart from "../../../../assets/img/cart.svg";
import defaultUserImage from "../../../../assets/img/default-user-image.jpg";
import routes from "../../../../Routes";
import SweetAlertBox from "../../../reusable/SweetAlertBox";
import IconBell from "./IconBell";
import SearchBarForResponsiveView from "../SearchBar/SearchBarForResponsiveView"
global.firebaseInitialization();

class HeaderMiddleSection extends Component {
  constructor(props) {
    super(props);
    this.delayedCallbackForSearchProduct = _.debounce(this.searchProduct, 1000);
    this.delayedCallbackForHandleUserProfile = _.debounce(this.handleUserProfile, 1000);

  }
  state = {
    userMenu: [
      {
        id: "account_info",
        displayText: "Account Info",
        path: "/account/account-info"
      },     
      {
        id: "logout",
        displayText: "Logout",
        path: ""
        // path: ""
      }
    ],
    showModal: false,
    userProfileDetails: {},
    isAlert: false,
    alertStyle: "",
    errorMessage: "",
    productsInCart: "",
    SearchText: "",
    showMenu: false,
    redirectToUserProfile: false,
    linkApp: ''
  };
  
  componentDidMount = () => {
    this.props.addToCart();
    if (global.getAuthToken()) {
      this.getUserProfileDetails();
      this.handleUserProfile(this.props);
    }

    this.props.getDevice().then(res => {
      if(res.data){
        const device = res.data.toLowerCase()
        const linkApp = device.includes('android') ? 'https://play.google.com/store/apps/details?id=com.app.totalit.aspenku' : 'https://apps.apple.com/id/app/aspenku/id1534126166?l=id'
        this.setState({ 
          linkApp 
        }, () => {
          setTimeout(() => {
            this.linkAppRef.click()
          }, 2000);
          
        })
      }
    })  

  };

  componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      this.setState({ redirectToUserProfile: false }, () => {
        this.delayedCallbackForHandleUserProfile(nextProps);
      })
      if (!_.isEmpty(nextProps.cartInfo)) {
        let products = 0,
          length;
        length = nextProps.cartInfo.length;
        for (let i = 0; i < length; i++) {
          products = products + nextProps.cartInfo[i].length;
        }
        this.setState({ productsInCart: products });
      } else {
        // this.props.addToCart();
        this.setState({ productsInCart: "" });
      }
      if (!_.isEmpty(nextProps.user)) {
        this.setState({ userProfileDetails: nextProps.user },
          () => {
            // this.props.getCountOfUnreadMessage(this.state.userProfileDetails.sendbird_id)
          });
      } else {
        global.getAuthToken() && this.getUserProfileDetails();
      }
    }
  };

  handleUserProfile = (props) => {
    let user = props.user;
    // console.log(this.props.history)
    // console.log(props.location.pathname);
    if (global.getAuthToken() && user.id ) {
      if (!user.full_name || !user.mobile_phone_number) {
        if (props.location.pathname !== "/account/account-info") {
          // console.log(props.location.pathname);
          this.setState({ redirectToUserProfile: true })
        }
      }
    }
  }
  redirectUser = () => {
    this.setState({ redirectToUserProfile: false }, () => {
      this.props.history.push("/account/account-info");
    })

  }
  handleModal = () => {
    this.setState({ showModal: true });
    $("body").css("padding-right", "15px");
  };

  hideModal = () => {
    $("div").removeClass("modal-backdrop");
    $("body").removeClass("modal-open");
    $("body").removeAttr("style");
    this.setState({ showModal: false });
    if (global.getAuthToken()) {
      this.getUserProfileDetails();
    }
  };

  getUserProfileDetails = () => {
    this.props.getUserProfile().then(response => {
      if (response.statusCode === 200) {
        this.setState({
          userProfileDetails: response.data
        }, () => {
          // this.props.getCountOfUnreadMessage(this.state.userProfileDetails.sendbird_id)
        });
        // this.props.userProfile(response.data);
      } else {
        this.setState({
          isAlert: true,
          errorMessage:
            response.statusCode === 400
              ? global.errors.error400
              : response.message,
          alertStyle: "danger"
        });
      }
    });
  };
  handleLogout = async () => {
    await firebase
      .auth()
      .signOut()
      .then(response => { });
    let getUserInfo = await firebase.auth().currentUser;
    if (getUserInfo === null) {
      this.setState({ userProfileDetails: {} });
      let currentPath = this.props.history.location.pathname;
      let pathName = _.split(currentPath, "/");
      routes.map(route => {
        let routePath = _.split(route.path, "/");
        let intersectionOfPaths = _.intersection(routePath, pathName);
        // if ((route.path === "/" + pathName[1]) && route.checkAuthentication) {
        if (
          intersectionOfPaths.length === routePath.length &&
          route.checkAuthentication
        ) {
          this.props.history.push("/");
        }
        return true;
      });
      this.props.logout();
    }
  };

  handleSearchInputChange = e => {
    this.setState(
      {
        SearchText: e.target.value
      },
      () => {
        this.delayedCallbackForSearchProduct(this.state.SearchText);
      }
    );
  };

  searchProduct = searchText => {
    this.props.handleProductSearch(searchText);
    // this.props.history.push("/search-product");
  };
  handleSearchPage = () => {
    this.props.history.push({
      pathname: "/search-product",
      state: { productSearchText: this.state.SearchText }
    });
  };
  handleUserMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };

  dismissMenu = menuInfo => {
    if (menuInfo) {
      if (menuInfo.id === "logout") {
        this.handleLogout();
      } 
      // else {
      //   this.props.history.push(menuInfo.path);
      // }
    }
    this.setState({
      showMenu: false
    });
  };

  render() {
    let {
      userProfileDetails,
      productsInCart,
      userMenu,
      showMenu,
      SearchText,
      redirectToUserProfile,
      linkApp
    } = this.state;
    let menu = showMenu ? (
      <div className="menu main-menu">
        {/* <div className={"menu main-menu " + (active_menu === menu.id ? "d-block" : "")}> */}
        <ul>
          {userMenu.map((menuItem, index) => {
            return (
              <React.Fragment key={menuItem.id + "_" + index}>
                <li
                  className={
                    "menu-item " +
                    (this.props.location.pathname === menuItem.path
                      ? "active"
                      : "")
                  }
                  onClick={() => this.dismissMenu(menuItem)}
                >
                  {
                  menuItem.id === 'logout' ? menuItem.displayText :
                  (<NavLink to={menuItem.path}>
                  {menuItem.displayText}
                  </NavLink>)
                  }
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    ) : (
        ""
      );
    return (
      <>
      { linkApp ? 
        <div className="download-app d-flex align-items-center justify-content-between" >  
          <i className='fa fa-close' onClick={() => this.setState({ linkApp: '' }) } ></i>
          <span className='flex-1' >
            <i className="fa fa-mobile" ></i>   Download Aspenku App 
            <a href={linkApp} ref={ref => this.linkAppRef = ref } className='btn btn-download-app mx-2' >Open App</a>
          </span>
        </div> : ''
      }
        <div className="center">
          <div className="container">
            <div className="level">
              <div className="left">
                <div className="level-item">
                  <NavLink to="/">
                    <img src={logo} alt="Logo Aspenku" height="56" />
                  </NavLink>
                </div>

                {/* Search product input */}
                <div className="level-item is-fullwidth is-relative main-search">
                  {/* <NavLink to="/search-product" style={{ width: "100%" }}> */}
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      className="form-control search-header"
                      id="search"
                      placeholder="What are you looking for?"
                      onChange={e => this.handleSearchInputChange(e)}
                      onKeyPress={e => {
                        e.key === "Enter" &&
                          SearchText &&
                          this.handleSearchPage();
                      }}
                    />
                    <span className="icon-search">
                      <img
                        src={find}
                        alt="search"
                        onClick={() => {
                          SearchText && this.handleSearchPage();
                        }}
                      />
                    </span>
                  </div>
                  {/* </NavLink> */}
                </div>
              </div>
              <div className="right">
                {/* notification */}
                { <IconBell /> }
                {/* Shopping cart  */}
                <NavLink to="/shopping-cart" className="level-item" href="# ">
                  <img src={cart} alt="cart" />
                </NavLink>
                {productsInCart && (
                  <span className="custom-badge badge-notify">
                    {productsInCart && productsInCart}
                  </span>
                )}
                {global.getAuthToken() ? (
                  // Mobile view after login
                  <React.Fragment>
                    {/* <a
                      className="level-item hamburger"
                      href="# "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    > */}
                    <div
                      className={
                        "level-item hamburger user-login header-menu-item right_menu " +
                        (showMenu ? "active-menu-item " : "")
                      }
                      // href="# "
                      // id="accountMenu"
                      // data-toggle="dropdown"
                      // aria-haspopup="true"
                      // aria-expanded="false"
                      // onClick={() => this.handleUserMenu()}
                      onMouseEnter={() => this.handleUserMenu()}
                      onMouseLeave={() => this.handleUserMenu()}
                    >
                      <img
                        src={
                          global.config.imageUrl +
                          userProfileDetails.image_file_name
                        }
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = defaultUserImage;
                        }}
                        alt="user"
                      />
                      {menu}

                      {showMenu && (
                        <div className="menu main-menu">
                          {/* <div className={"menu main-menu " + (active_menu === menu.id ? "d-block" : "")}> */}
                          <ul>
                            {userMenu.map((menuItem, index) => {
                              return (
                                <React.Fragment key={menuItem.id + "_" + index}>
                                  <li
                                    className={
                                      "menu-item " +
                                      (this.props.location.pathname ===
                                        menuItem.path
                                        ? "active"
                                        : "")
                                    }
                                    onClick={() => this.dismissMenu(menuItem)}
                                  >
                                    {
                                    menuItem.id === 'logout' ? menuItem.displayText :
                                    (<NavLink to={menuItem.path}>
                                    {menuItem.displayText}
                                    </NavLink>)
                                    }
                                  </li>
                                </React.Fragment>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {/* </a> */}
                    </div>

                    {/* Web view after login */}
                    <div
                      className={
                        "level-item btn-login user-login header-menu-item right_menu " +
                        (showMenu ? "active-menu-item " : "")
                      }
                      // href="# "
                      // id="accountMenu"
                      // data-toggle="dropdown"
                      // aria-haspopup="true"
                      // aria-expanded="false"
                      // onClick={() => this.handleUserMenu()}
                      onMouseEnter={() => this.handleUserMenu()}
                      onMouseLeave={() => this.handleUserMenu()}
                    >
                      <span className="header-user-email text-right">
                        {userProfileDetails.full_name
                          ? userProfileDetails.full_name
                          : userProfileDetails.email}
                      </span>
                      <img
                        src={
                          global.config.imageUrl +
                          userProfileDetails.image_file_name
                        }
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = defaultUserImage;
                        }}
                        alt="user"
                      />

                      {menu}

                      {showMenu && (
                        <div className="menu main-menu">
                          {/* <div className={"menu main-menu " + (active_menu === menu.id ? "d-block" : "")}> */}
                          <ul>
                            {userMenu.map((menuItem, index) => {
                              return (
                                <React.Fragment key={menuItem.id + "_" + index}>
                                  <li
                                    className={
                                      "menu-item " +
                                      (this.props.location.pathname ===
                                        menuItem.path
                                        ? "active"
                                        : "")
                                    }
                                    onClick={() => this.dismissMenu(menuItem)}
                                  >
                                    {
                                    menuItem.id === 'logout' ? menuItem.displayText :
                                    (<NavLink to={menuItem.path}>
                                    {menuItem.displayText}
                                    </NavLink>)
                                    }
                                  </li>
                                </React.Fragment>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <a
                        className="level-item hamburger header-menu-item right_menu"
                        href="#loginID"
                        data-toggle="modal"
                        onClick={this.handleModal}
                      >
                        <span className="fa fa-user-o"></span>
                      </a>
                      <button
                        className="level-item btn btn-login btn-orange"
                        onClick={this.handleModal}
                      // role="button"
                      // data-toggle="modal"
                      >
                        Login / Register
                    </button>
                    </React.Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>
        <SearchBarForResponsiveView
          searchText={SearchText}
          handleSearchInputChange={this.handleSearchInputChange}
          handleSearchPage={this.handleSearchPage}
        />
        {this.state.showModal && (
          <Login hideModal={this.hideModal} {...this.props} />
        )}
        {redirectToUserProfile && (
          <SweetAlertBox
            title="Incomplete Profile"
            message="Please complete your profile."
            confirmBtnText="Ok"
            onConfirm={this.redirectUser}
          ></SweetAlertBox>
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  user: state.login.user,
  cartInfo: state.cart.cartInfo,
  store: state.store
});
export default withRouter(
  connect(mapStateToProps, {
    getUserProfile,
    logout,
    // userProfile,
    addToCart,
    handleProductSearch,
    getDevice    
  })(HeaderMiddleSection)
);

// {/* {this.props.history.location.pathname ===
//   "/account" ? (
// <div className="level-item btn-login user-login">
//   <img src={defaultUserImage} alt="user" />
//   <span>{userProfileDetails.email
//     ? userProfileDetails.email
//     : ""}
//   </span>
// </div>
// ) : ( */}

// export default withRouter(
//   connect(mapStateToProps, {
//     getUserProfile,
//     logout,
//     // userProfile,
//     addToCart,
//     handleProductSearch
//   })(HeaderMiddleSection)
// );

// {/* <ul
// className="dropdown-menu dropdown-menu-right"
// aria-labelledby="accountMenu"
// >
// <li>
//   <NavLink
//     className="dropdown-item text-right"
//     to="/account/account-info"
//   >
//     Account Info
//   </NavLink>
// </li>
// <li>
//   <NavLink
//     className="dropdown-item text-right"
//     to="/account/manage-address"
//   >
//     Manage Address
//   </NavLink>
// </li>
// <li>
//   <span
//     className="dropdown-item text-right cursor-pointer"
//     onClick={this.handleLogout}
//   >
//     Logout
//   </span>
// </li>
// </ul> */}
