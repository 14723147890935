import React, { Component } from "react";
import * as firebase from "firebase/app";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
// import ReactTelephoneInput from "react-telephone-input";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";
import "react-phone-input-2/lib/style.css";
import "firebase/auth";
import global from "../../global";
import { registerApi } from "../../actions";
import Footer from "../wrapperComponents/Footer";
import SocialLogin from "../Login/SocialLogin";
import logo from "../../assets/img/logo-white@2x.png";
import register from "../../assets/img/asset-register.svg";
import facebook from "../../assets/img/facebook.svg";
import google from "../../assets/img/google-o.svg";
import Login from "../Login";
import MessageAlert from "../reusable/MessageAlert";
import TextInput from "../reusable/FormControls/TextInput";
import "./Register.scss";
import Loader from "../reusable/LoaderSpinner";
global.firebaseInitialization();
class Register extends Component {
  constructor(props) {
    super(props);
    this.delayedCallback = _.debounce(this.handleSubmit, 4000);
  }
  state = {
    showPassword: false,
    alertStyle: "",
    alertMessage: "",
    formData: {
      mobile_phone_number: "",
      email_Address: "",
      password: "",
      confirm_Password: "",
      full_name: ""
    },
    formErrors: {
      mobile_phone_number: false,
      email_Address: false,
      password: "",
      confirm_Password: "",
      full_name: false
    },
    invalidEmail: "Invalid Email Address.",
    invalidPhoneNumber: "Phone Number should contains 10 Digit.",
    isClicked: {
      password: "",
      confirm_Password: ""
    },
    fbDisabled: false,
    googleDisabled: false,
    isLoading: false,
    isDisabled: false,
  };
  componentDidMount = () => {
    global.setTitleOfPage("Registration Page");
  }
  handleChange = (e, name) => {
    let element_Name = e.target.name;
    let element_Value = e.target.value;
    let value = true;
    if (element_Name === "email_Address") {
      value = element_Value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      if (value) {
        this.setState({
          formErrors: { ...this.state.formErrors, [element_Name]: false }
        });
      } else {
        this.setState({
          formErrors: { ...this.state.formErrors, [element_Name]: true }
        });
      }
    } else if (element_Name === "password" || element_Name === "confirm_Password") {
      if (!element_Value.match(/\s/g)) {
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            [element_Name]: ""
          }
        });
        value = true;
        if (element_Name === "confirm_Password") {
          if (this.state.formData.password && this.state.formData.password.length >= 8) {
            if (this.state.formData.password === element_Value) {
              value = true;
              this.setState({
                formErrors: {
                  ...this.state.formErrors,
                  [element_Name]: "",
                  password: ""
                }
              });
            } else {
              this.setState({
                formErrors: {
                  ...this.state.formErrors,
                  [element_Name]: "Password Mismatch."
                }
              });
            }
          }
        } else {
          if (element_Value.length >= 8) {
            if (this.state.formData.confirm_Password) {
              if (this.state.formData.confirm_Password === element_Value) {
                value = true;
                this.setState({
                  formErrors: {
                    ...this.state.formErrors,
                    [element_Name]: "",
                    confirm_Password: ""
                  }
                });
              } else {
                value = false;
                this.setState({
                  formErrors: {
                    ...this.state.formErrors,
                    [element_Name]: "Password Mismatch."
                  }
                });
              }
            }
          } else {
            this.setState({
              formErrors: {
                ...this.state.formErrors,
                [element_Name]: "Password must be atleast 8 characters long."
              }
            });
          }
        }
      } else {
        value = false;
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            [element_Name]: "Password should not contain white space."
          }
        });
      }
    }
    else {
      if (element_Value) {
        this.setState({
          formErrors: { ...this.state.formErrors, [element_Name]: false }
        });
      }
      else {
        this.setState({
          formErrors: { ...this.state.formErrors, [element_Name]: "Field is required." }
        });
      }
    }
    this.setState({
      formData: { ...this.state.formData, [element_Name]: element_Value }
    });
  };

  handlePhoneNumber = (e, data) => {
    // console.log(e, data);
    let element_Value = e;
    let dialCode = data.dialCode;
    let phoneNumberDigits = element_Value.replace(/[^\d]/g, "");
    let phoneNumber = phoneNumberDigits.substring(dialCode.length);
    this.setState({
      formData: {
        ...this.state.formData,
        mobile_phone_number: element_Value,
        dialCode: data.dialCode
      },
      ...(phoneNumber[0] === "0"
        ? {
          formErrors: { ...this.state.formErrors, mobile_phone_number: true }
        }
        : {
          formErrors: { ...this.state.formErrors, mobile_phone_number: false }
        })
    });
  };
  isValid = (value, country) => {
    // console.log(value, country);
    if (value) {
      if (value.match(/12345/)) {
        // console.log("Inavalid");
        return 'Invalid value: ' + value + ', ' + country.name;

      } else if (value.match(/1234/)) {
        // console.log("Inavalid");
        return false;
      } else {
        // console.log("valid");
        return true;
      }
    }

  }
  handleSignup = e => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      isDisabled: true
    });
    window.scrollTo(0, 0);
    let { email_Address, password } = this.state.formData;
    // firebase.initializeApp(global.firebaseConfig);
    let actionCodeSettings = {
      url: global.config.homePageUrl,
    }
    firebase
      .auth()
      .createUserWithEmailAndPassword(email_Address, password)
      .then(async response => {       
        var uid = response.user.uid;

        let getUserInfo = await firebase.auth().currentUser;
        getUserInfo
          .sendEmailVerification(actionCodeSettings)
          .then(res => {
            this.setState({
              alertStyle: "success",
              alertMessage:
                "Your account registered successfully. Confirmation mail sent to your registered email. Please verify your email and login.",
            });
          })
          .catch(error => {
            this.setState({
              isDisabled: false,
              isLoading: false,
              alertStyle: "danger",
              alertMessage: "Error while sending verification email."
            });
          });

        let authentication_Token = await getUserInfo.getIdToken();
        this.actionGtag()
        this.delayedCallback(uid, authentication_Token);
        // this.handleSubmit(uid, authentication_Token);
      })
      .catch(error => {
        if (
          error.code === "auth/email-already-in-use" ||
          error.code === "auth/invalid-email"
        ) {
          this.setState({
            isDisabled: false,
            isLoading: false,
            alertStyle: "danger",
            alertMessage: error.message
          });
        }
      });
  };
  handleSubmit = (uid, authentication_Token) => {
    let payload = {
      uid: uid,
      authentication_token: authentication_Token,
      email: this.state.formData.email_Address,
      mobile_phone_number: this.state.formData.mobile_phone_number,
      password: this.state.formData.password,
      full_name: this.state.formData.full_name
    };
    // console.log(payload)
    this.props.registerApi(payload).then(response => {
      this.setState({ isLoading: false }, () => {
        this.props.history.push("/")
      })
      // if (response.statusCode === 200) {
      //   // this.props.history.push("/");
      //   this.setState(
      //     {
      //       formData: {
      //         mobile_phone_number: "",
      //         email_Address: "",
      //         password: "",
      //         confirm_Password: ""
      //       },
      //     },
      //     () => {
      //       this.props.history.push("/");
      //     }
      //   );
      // } else {
      //   this.setState({
      //     alertMessage:
      //       response.statusCode === 400
      //         ? global.errors.error400
      //         : response.message,
      //     alertStyle: "danger"
      //   });
      // }
    });
  };
  handlePassword = e => {
    let id = e.target.id;
    this.setState({
      showPassword:
        this.state.isClicked === id ? !this.state.showPassword : true,
      isClicked: {
        ...this.state.isClicked,
        [id]: this.state.isClicked[id] ? "" : true
      }
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertStyle: "",
      alertMessage: ""
    });
  };
  setErrorMessage = (alertStyle, message) => {
    this.setState({
      alertStyle: alertStyle,
      alertMessage: message
    });
  };
  setDisable = type => {
    if (type === "facebook") {
      this.setState({ fbDisabled: !this.state.fbDisabled });
    } else if (type === "google") {
      this.setState({ googleDisabled: !this.state.googleDisabled });
    }
  };
  actionGtag = () => {
    window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-613539965/2ixrCNCOzYkCEP3Ax6QC'});
  }
  render() {
    let {
      showPassword,
      alertMessage,
      alertStyle,
      formData,
      formErrors,
      invalidEmail,
      isClicked,
      fbDisabled,
      googleDisabled,
      isLoading,
      isDisabled
    } = this.state;
    let disabled = "";
    Object.keys(formData).map((key, index) => {
      if (!formData[key]) {
        disabled = true;
      }
      return disabled;
    });
    Object.keys(formErrors).map((key, index) => {
      if (formErrors[key]) {
        disabled = true;
      }
      return disabled;
    });
    return (
      <React.Fragment>
        {alertMessage ? (
          <MessageAlert
            alertType={alertStyle}
            message={alertMessage}
            handleAlertClose={this.handleAlertClose}
          />
        ) : (
            ""
          )}
        <header className="register">
          <div className="container">
            <NavLink to="/">
              <img
                src={logo}
                className="img-fluid logo-white"
                alt="logo aspenku white"
              />
            </NavLink>
          </div>
        </header>
        <div className="container">
          <div className="box-register row">
            <div className="col-md-6">
              <div className="title">Let’s join with Aspenku!</div>
              <div
              // className="subtitle"
              >
                Already have an account?
                <span className="sign-in" onClick={this.props.handleModal}>
                  Sign in
                </span>
              </div>
              <img
                src={register}
                alt="register"
                className="asset-register img-fluid"
              />
              <p>
                Aplikasi sarana penunjang
                <br></br>
                ekspor nasional untuk Indonesiaku
              </p>
            </div>
            <div className="col-md-6 form-sign">
              <div className="sign-with">
                Signup with
                <SocialLogin
                  setErrorMessage={this.setErrorMessage}
                  setLoaderAndDisable={this.setDisable}
                  googleDesign={renderProps => (
                    <button
                      onClick={(e) => {
                        this.actionGtag()
                        renderProps.onClick(e)
                      }}
                      className="btn-social"
                      disabled={googleDisabled}
                    >
                      <img
                        src={google}
                        alt="google"
                        className={
                          "img-fluid google" +
                          `${googleDisabled ? " img-disabled" : ""}`
                        }
                      />
                    </button>
                  )}
                  facebookDesign={renderProps => (
                    <button
                      onClick={(e) => {
                        this.actionGtag()
                        renderProps.onClick(e)
                      }}
                      className="btn-social"
                      disabled={fbDisabled}
                    >
                      <img
                        src={facebook}
                        alt="fb"
                        className={
                          "img-fluid fb" +
                          `${fbDisabled ? " img-disabled" : ""}`
                        }
                      />
                    </button>
                  )}
                />
              </div>
              <div className="display-message">
              Currenctly the Login by Facebook & Sign in by Google can only work
              for Android users. iOS users should register using the standard email and
              password registration form
            </div>
              <div
                className="is-divider"
                data-content="or with form below"
              ></div>
              <form className="login">
                <div className="form-group">
                  <TextInput
                    type="text"
                    placeholder="Full Name"
                    name="full_name"
                    value={formData.full_name}
                    onChange={e => this.handleChange(e)}
                  />
                  {formErrors.full_name && (
                    <div className="form-group">
                      <span className="help-block">{formErrors.full_name}</span>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <TextInput
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    name="email_Address"
                    value={formData.email_Address}
                    onChange={e => this.handleChange(e)}
                    autoComplete="email"
                  />
                  {formErrors.email_Address && (
                    <div className="form-group">
                      <span className="help-block">{invalidEmail}</span>
                    </div>
                  )}
                </div>
                {/* <div className="form-group is-relative">
                <ReactTelephoneInput
                  defaultCountry="in"
                  inputProps={inputProps}
                  listItemClassName="dropdown-item"
                  classNames="form-group is-relative"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                /> 
                 </div>  */}
                <div className="form-group">
                  <PhoneInput
                    name="mobile_phone_number"
                    value={formData.mobile_phone_number}
                    // defaultCountry={"in"}
                    countryCodeEditable={false}
                    inputClass="form-control"
                    placeholder="Mobile Phone Number"
                    onChange={(e, data) => this.handlePhoneNumber(e, data)}
                    inputExtraProps={{ autoComplete: "mobile-Phone-Number" }}
                  // isValid={(value, country) => this.isValid(value, country)}
                  />
                  {formErrors.mobile_phone_number && (
                    <div className="form-group">
                      <span className="help-block">
                        Please enter valid phone number.
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={`form-group is-relative ${
                    formErrors.password ? "mb-0" : ""
                    }`}
                >
                  <TextInput
                    type={
                      showPassword && isClicked.password ? "text" : "password"
                    }
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={e => this.handleChange(e)}
                    autoComplete="new-password"
                  />
                  <span
                    id="password"
                    className={
                      showPassword && isClicked.password
                        ? "fa fa-eye show-pass "
                        : "fa fa-eye-slash show-pass"
                    }
                    toggle="#password-field"
                    onClick={e => this.handlePassword(e)}
                  ></span>
                </div>
                {formErrors.password && (
                  <div className="form-group">
                    <span className="help-block">{formErrors.password}</span>
                  </div>
                )}
                <div
                  className={`form-group is-relative ${
                    formErrors.confirm_Password ? "mb-0" : ""
                    }`}
                >
                  <TextInput
                    type={
                      showPassword && isClicked.confirm_Password
                        ? "text"
                        : "password"
                    }
                    placeholder="Confirm Password"
                    name="confirm_Password"
                    value={formData.confirm_Password}
                    onChange={e => this.handleChange(e)}
                    disabled={formData.password ? "" : "disabled"}
                    autoComplete="confirm-password"
                  />
                  <span
                    id="confirm_Password"
                    className={
                      showPassword && isClicked.confirm_Password
                        ? "fa fa-eye show-pass "
                        : "fa fa-eye-slash show-pass"
                    }
                    toggle="#password-field"
                    onClick={e => this.handlePassword(e)}
                  ></span>
                </div>
                {formErrors.confirm_Password && (
                  <div className="form-group">
                    <span className="help-block">
                      {formErrors.confirm_Password}
                    </span>
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  onClick={e => this.handleSignup(e)}
                  disabled={disabled || isDisabled ? "disabled" : ""}
                >
                  {/* Sign up */}

                  {isLoading ? (
                    <span className="d-flex align-items-center justify-content-center">
                      <Loader
                        width={18}
                        height={18}
                        borderWidth={2}
                        color="#bbb4b4"
                      />
                      &nbsp;Sign up
                  </span>
                  ) : (
                      "Sign up"
                    )}
                </button>
                <div className="confirmation">
                  <p>By creating an account, I agree to</p>
                  <NavLink to="#">Aspenku Free Membership Agreement</NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer></Footer>
        {this.props.showModal && (
          <Login hideModal={this.props.hideModal}></Login>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(connect(null, { registerApi })(Register));
