import {
  CATEGORIES, PAGES,
  // SETCURRENCY,
  CHANGECURRENCY
} from "./typesOfActions";
import global from "../global";
// let auth = global.getAuthToken() ? null : "basicAuth";
export const getBanners = () => (dispatch, getState, Api) => {
  return Api.get("banners", "basicAuth").then(response => {
    return response;
  });
};

export const getCountries = payload => (dispatch, getState, Api) => {
  let url = "countries?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sortBy=${payload.sortBy}`;
  }
  if (payload.sortByValue) {
    url += `&sortByValue=${payload.sortByValue}`;
  }
  return Api.get(url, "basicAuth").then(response => {
    return response;
  });
};

export const getProvinces = payload => (dispatch, getState, Api) => {
  let url = "provinces?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sortBy=${payload.sortBy}`;
  }
  if (payload.sortByValue) {
    url += `&sortByValue=${payload.sortByValue}`;
  }
  if (payload.country_id) {
    url += `&country_id=${payload.country_id}`;
  }
  return Api.get(url, "basicAuth").then(response => {
    return response;
  });
};

export const getCities = payload => (dispatch, getState, Api) => {
  let url = "cities?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.province_id) {
    url += `&province_id=${payload.province_id}`;
  }
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sortBy=${payload.sortBy}`;
  }
  if (payload.sortByValue) {
    url += `&sortByValue=${payload.sortByValue}`;
  }
  return Api.get(url, "basicAuth").then(response => {
    return response;
  });
};

export const getDistricts = payload => (dispatch, getState, Api) => {
  let url = "districts?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.city_id) {
    url += `&city_id=${payload.city_id}`;
  }
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sortBy=${payload.sortBy}`;
  }
  if (payload.sortByValue) {
    url += `&sortByValue=${payload.sortByValue}`;
  }
  return Api.get(url, "basicAuth").then(response => {
    return response;
  });
};

export const getSubDistricts = payload => (dispatch, getState, Api) => {
  let url = "subDistricts?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.district_id) {
    url += `&district_id=${payload.district_id}`;
  }
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sortBy=${payload.sortBy}`;
  }
  if (payload.sortByValue) {
    url += `&sortByValue=${payload.sortByValue}`;
  }
  return Api.get(url, "basicAuth").then(response => {
    return response;
  });
};

export const getShippingMethods = payload => (dispatch, getState, Api) => {
  return Api.get("shippingMethods").then(response => {
    return response;
  });
};

export const getCategories = payload => (dispatch, getState, Api) => {
  return Api.get(
    "category?subCategory=" + payload.subCategory,
    "basicAuth"
  ).then(response => {
    if (response.statusCode === 200) {
      dispatch({
        type: CATEGORIES,
        data: response.data.rows
      });
    }
    return response;
  });
};

export const getCategoryDetails = payload => (dispatch, getState, Api) => {
  return Api.get("user/category/" + payload.permalink , "basicAuth", "v3").then(
    response => {
      return response;
    }
  );
};

export const getSubCategories = payload => (dispatch, getState, Api) => {
  return Api.get(
    "subCategory?category_id=" + payload.category_id,
    "basicAuth"
  ).then(response => {
    return response;
  });
};

export const getProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  let id = "";  
  const sort = payload.sortBy ? "&sort=" + payload.sortBy : '';
  payload.category_id
    ? (id = "category=" + payload.category_id)
    : (id = "subCategory=" + payload.subCategory_id);
  return Api.get(
    "product?limit=" + payload.limit + "&skip=" + payload.skip + "&" + id + sort,
    auth
  ).then(response => {
    return response;
  });
};

export const getProductsHalal = payload => (dispatch, getState, Api) => {
  let url = "product/halalPage?limit=" + payload.limit + "&skip=" + payload.skip
  if (payload.sortBy) {
    url += `&sort=${payload.sortBy}`;
  }
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get( url, auth, "v3").then(response => {
    return response;
  });
};

export const getHalalPageBanner = () => (dispatch, getState, Api) => {  
  return Api.get("halalPageBanner", "basicAuth", "v3").then(response => {
    return response;
  });
};

export const uploadFile = payload => (dispatch, getState, Api) => {
  return Api.post("files", payload, "formData").then(response => {
    return response;
  });
};

export const getFeaturedProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/featured?limit=" + payload.limit + "&skip=" + payload.skip,
    auth
  ).then(response => {
    return response;
  });
};

export const getNewArrivalProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/newArrival?limit=" + payload.limit + "&skip=" + payload.skip,
    auth
  ).then(response => {
    return response;
  });
};

export const getBestSellingProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/bestSelling?limit=" + payload.limit + "&skip=" + payload.skip,
    auth
  ).then(response => {
    return response;
  });
};

export const getHalalProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/halal?limit=" + payload.limit + "&skip=" + payload.skip,
    auth,
    "v3"
  ).then(response => {
    return response;
  });
};

export const getProductsBasedCountry = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/productByCountry?limit=" + payload.limit + "&skip=" + payload.skip,
    auth,
    "v3"
  ).then(response => {
    return response;
  });
};


export const getRecommendedProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/recommended?limit=" + payload.limit + "&skip=" + payload.skip,
    auth
  ).then(response => {
    return response;
  });
};

export const getRelatedProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  return Api.get(
    "product/related?sub_category_id=" + payload.subcategory_id + "$limit=" + payload.limit + "&skip=" + payload.skip ,
    auth
  ).then(response => {
    return response;
  });
};

export const getDevice = () => (dispatch, getState, Api) => {
  return Api.get('deviceDetected', 'basicAuth', 'v3').then(response => {
    return response;
  });
};

export const getProductDetails = permalink => (dispatch, getState, Api) => {
  return Api.get("product/" + permalink, "basicAuth", 'v3' ).then(response => {
    return response;
  });
};

export const getUnitMeasures = () => (dispatch, getState, Api) => {
  return Api.get("unitMeasures").then(response => {
    return response;
  });
};
export const getReasons = params => (dispatch, getState, Api) => {
  let type = "";
  if (params.type) {
    type = "type=" + params.type;
  }
  return Api.get("cancelReason?" + type).then(response => {
    return response;
  });
};
export const handleFooterPages = () => (dispatch, getState, Api) => {
  return Api.get("singlepage", "basicAuth").then(response => {
    if (response.statusCode === 200) {
      dispatch({
        type: PAGES,
        data: response.data
      });
    }
    return response
  })
}
export const getSubBanners = () => (dispatch, getState, Api) => {
  return Api.get("subBanners", "basicAuth").then(response => {
    return response;
  });
};
export const getCurrentLocationOfUser = () => (dispatch, getState, Api) => {
  return Api.get("iplookup", "basicAuth").then(response => {
    if (response.statusCode === 200) {
      let country = response.data.country;
      let currencyType = country === "ID" ? "IDR" : "USD";
      if (!JSON.parse(localStorage.getItem("currency"))) {
        dispatch({
          type: CHANGECURRENCY,
          currencyType: currencyType
        })
      }
    }
  })
}

export const sendFormOrnamentalPlant = (payload) => (dispatch, getState, Api) => {  
  return Api.post("popUp/ornamentalPlant", payload, "basicAuth", "v3").then(response => {
    return response;
  });
};
