import React, { Component } from "react";
// import bell from "../../../../assets/img/bell.svg";

import { NavLink } from "react-router-dom";
import { listenNotificationStore, listenNotificationUser } from "../../../../actions/chatFirebase";
import * as firebase from "firebase/app";
import { connect } from "react-redux";

class IconBell extends Component {
    state = {
        newMessageStore: 0,
        newMessageUser: 0,
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                listenNotificationUser(user.uid,
                    newMessageUser => {
                        this.setState({ newMessageUser })
                    })

            }
        });

        setTimeout(() => {
            if (this.props.store.storeInfo && this.props.store.storeInfo.id) {
                listenNotificationStore(this.props.store.storeInfo.id,
                    newMessageStore => {
                        this.setState({ newMessageStore })
                    })

            }
        }, 5000)

    }


    componentWillReceiveProps(nextProps) {
        if((nextProps.store.storeInfo && nextProps.store.storeInfo.id) !== (this.props.store.storeInfo && this.props.store.storeInfo.id) ) {
            this.componentDidMount()
        }

    }

    render() {
        return (<>
            <NavLink to="/notification" className="level-item" href="# ">
                <i className="fa fa-comments" style={{ color: '#fff' , fontSize: '24px' }} ></i>
            </NavLink>
            {(this.state.newMessageStore + this.state.newMessageUser) > 0 ?
                <span className="custom-badge badge-notify font-weight-bold ">
                    {this.state.newMessageStore + this.state.newMessageUser}
                </span> : ''}
        </>)
    }
}

export default connect(state => ({
    store: state.store
}))(IconBell)
