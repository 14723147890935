import Axios from 'axios'
import global from '../global'

import { createHeaders } from './headerAPI'

export const closePopupCreateStoreService = (user_id) => {
    return Axios({
        method : 'PUT',
        headers : createHeaders() ,
        url : global.config.apiUrlV3 + 'user/login/'+ user_id,
    })         
}
