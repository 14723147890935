// import LOGIN from "./typesOfActions";
import { LOGOUT , USERPROFILE } from "./typesOfActions";
import { getStore } from "./store";
import { getWishlistProducts } from "./product";
import history from "../Utilities/history";
import global from "../global";
import Cookies from "universal-cookie";
// import routes from "../Routes";
import * as firebase from "firebase/app";
import "firebase/auth";
global.firebaseInitialization();
const cookies = new Cookies();
export const loginApi = payload => (dispatch, getState, Api) => {
  return Api.post("auth/login", payload, "basicAuth").then(response => {
    if (response.statusCode === 200) {
      dispatch(doLogin(response, payload));
      dispatch({ type : USERPROFILE , user : response.data  })
    }
    return response;
  });
};

export const doLogin = (response, payload) => (dispatch, getState, Api) => {
  cookies.set("user_Token", payload.authentication_token, { path: "/" });
  // cookies.set("uid", payload.uid, { path: "/" });
  // cookies.set("user_Email", response.data.email, { path: "/" });
  dispatch(getStore());
  dispatch(getWishlistProducts());
};
export const handleUnauthorisedUsers = () => (dispatch, getState, Api) => {
  cookies.remove("user_Token", { path: "/" });
  cookies.remove("uid", { path: "/" });
  cookies.remove("user_Email", { path: "/" });
  localStorage.removeItem("products");
  localStorage.removeItem("notification")
  history.push("/");
  dispatch({
    type: LOGOUT
  });
};

export const logout = location => async (dispatch, getState, Api) => {
  cookies.remove("user_Token", { path: "/" });
  cookies.remove("uid", { path: "/" });
  cookies.remove("user_Email", { path: "/" });
  localStorage.removeItem("products");
  localStorage.removeItem("notification")
  await firebase
    .auth()
    .signOut()
    .then(response => { });
  let getUserInfo = await firebase.auth().currentUser;
  if (getUserInfo === null) {
    history.push("/");
    // let currentPath = history.location.pathname;
    // let pathName = _.split(currentPath, "/");
    // routes.map(route => {
    //   let routePath = _.split(route.path, "/");
    //   let intersectionOfPaths = _.intersection(routePath, pathName);
    //   // if ((route.path === "/" + pathName[1]) && route.checkAuthentication) {
    //   if (
    //     intersectionOfPaths.length === routePath.length &&
    //     route.checkAuthentication
    //   ) {
    //     history.push("/");
    //   }
    //   return true;
    // });
  }

  dispatch({
    type: LOGOUT
  });
};
