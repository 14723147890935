import React, { Component } from 'react'
import global from "../../../global";
import { withRouter } from "react-router-dom";
import defaultProductImage from "../../../assets/img/default_product_image.png";
import _ from "lodash";
import "./Page.scss"
import Error404 from "../../reusable/Error404";
class Page extends Component {
    static contextType = global.loaderContext;
    state = {
        page: {}
    }
    componentWillMount = () => {
        global.setTitleOfPage(this.props.data.title);
        this.context.hideLoader();
    }
    componentWillReceiveProps = (nextProps) => {
        global.setTitleOfPage(nextProps.data.title);
        if (this.props !== nextProps) {
            this.setState({ page: nextProps.data })
            this.context.hideLoader();


        }
    }
    render() {
        // console.log(this.props.data)
        let { page } = this.state

        
        return (
            <>
                {_.isEmpty(page) ? <Error404 message="No data found!" hideError404></Error404> :
                    <div className="container cart">
                        <div className="news-detail">
                            <div className="detail-img">
                                <img
                                    src={global.config.imageUrl + page.image_file_name}
                                    className="img-fluid"
                                    alt="Blog"
                                    onError={e => {
                                        e.target.onError = null;
                                        e.target.src = defaultProductImage;
                                    }}
                                />
                            </div>
                            <div className="description mt-5">
                                <div className="title">
                                    {/* Mengenal Dokumen dalam Proses Ekspor */}
                                    {page.title}
                                    {/* {console.log('tes', page.title)} */}
                                </div>
                                <div className="desc" dangerouslySetInnerHTML={{ __html: page.content }} ></div>
                                {/* <p className="desc" >{pageDetail.content}</p> */}
                            </div>
                        </div>
                    </div>
                }
               
            </>
        )
    }
}
export default withRouter(Page);