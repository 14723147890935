import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider} from "react-redux";
import history from "./Utilities/history";
import Homepage from "./components/HomePage";
import Register from "./components/Register";
import $ from "jquery";
import global from "./global";
import Loader from "./components/reusable/Loader";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist'
import { Helmet } from 'react-helmet'
const Error404 = React.lazy(() => import("./components/reusable/Error404"));
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
// const isAuthenticated = cookies.get("user_Token") ? false : true;
class App extends Component {
  state = {
    showModal: false
  };
  componentDidMount(){
    if (process.env.NODE_ENV === "production") {
      if (window) {
        window.console.log = function() {};
      }
    }
    const paramGlobal = new URLSearchParams(window.location.search)
    paramGlobal.get('funnel_id') && localStorage.setItem('funnel_id', paramGlobal.get('funnel_id') ) 
  }

  handleModal = () => {
    this.setState({ showModal: true });
    $("body").css("padding-right", "15px");
  };

  hideModal = () => {
    $("div").removeClass("modal-backdrop");
    $("body").removeClass("modal-open");
    $("body").removeAttr("style");
    this.setState({ showModal: false });
  };
  render() {
    return (<>
      <Helmet>
        <meta name="description" content="Indonesian Exporters Marketplace, Find quality Manufacturers, Suppliers, Exporters, Importers, Buyers, Wholesalers, Products and Trade Leads from our award-winning International Trade Site. Import ; Import on Aspenku.com" />
      </Helmet>
      <Provider store={this.props.store}>
        <PersistGate persistor={persistStore(this.props.store)} loading={null}>
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path="/register"
                render={props => {
                  return (
                    <>
                      {global.getAuthToken() ? (
                        <Redirect to="/" />
                      ) : (
                        <Register
                          showModal={this.state.showModal}
                          handleModal={this.handleModal}
                          hideModal={this.hideModal}
                          {...props}
                        />
                      )}
                    </>
                  );
                }}
              />
              <Route
                exact
                path="/error404"
                render={() => <Error404 message="Page not found!" />}
              />
              <Route
                path="/"
                render={props => (
                  // <ModalContext.Provider value={{
                  //   showModal: this.state.showModal,
                  //   handleModal: this.handleModal,
                  //   hideModal: this.hideModal
                  // }}>
                  <Homepage
                    showModal={this.state.showModal}
                    handleModal={this.handleModal}
                    hideModal={this.hideModal}
                    {...props}
                  />
                  // </ModalContext.Provider>
                )}
              />
            </Switch>
          </Suspense>
        </Router>
        </PersistGate>
      </Provider>
    </>);
  }
}

export default (App);
