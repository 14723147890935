import $ from "jquery";
// slick
// $('#featured-product').slick({
// infinite: true,
// slidesToShow: 5,
// slidesToScroll: 5,
// responsive: [
//     {
//     breakpoint: 1024,
//     settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3
//     }
//     },
//     {
//     breakpoint: 769,
//     settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3
//     }
//     },
//     {
//     breakpoint: 480,
//     settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//     }
//     }
// ]
// });
// $('#new-arrival').slick({
// infinite: true,
// slidesToShow: 5,
// slidesToScroll: 5,
// responsive: [
//     {
//     breakpoint: 1024,
//     settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         infinite: true,
//         dots: true
//     }
//     },
//     {
//     breakpoint: 769,
//     settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3
//     }
//     },
//     {
//     breakpoint: 480,
//     settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//     }
//     }
// ]
// });
// $('#best-seller').slick({
// infinite: true,
// slidesToShow: 5,
// slidesToScroll: 5,
// responsive: [
//     {
//     breakpoint: 1024,
//     settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3
//     }
//     },
//     {
//     breakpoint: 769,
//     settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3
//     }
//     },
//     {
//     breakpoint: 480,
//     settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//     }
//     }
// ]
// });
// $('#six-product').slick({
//     infinite: true,
//     slidesToShow: 6,
//     slidesToScroll: 6,
//     responsive: [
//         {
//         breakpoint: 1024,
//         settings: {
//             slidesToShow: 3,
//             slidesToScroll: 3
//         }
//         },
//         {
//         breakpoint: 769,
//         settings: {
//             slidesToShow: 3,
//             slidesToScroll: 3
//         }
//         },
//         {
//         breakpoint: 480,
//         settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1
//         }
//         }
//     ]
// });
// $('#highlightNews').slick({
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     dots: true,
//     arrows: false
// });
// toggle sub menu
$(".menu-categories").click(function () {
  $(".menu-subcategories").toggle();
});
// show pass
$(".show-pass").on("click", function () {
  if ($("#password").attr("type") === "password") {
    $("#password").attr("type", "text");
  } else {
    $("#password").attr("type", "password");
  }
});
// menu categories
$(".accordion .sub-menu").hide();
function menu(id) {
  $("#sub" + id).toggle();
}

// add & delete
$(".multiple").on("click", ".delete", function () {
  $(".delete")
    .closest(".multiple")
    .find(".custom-file")
    .not(":first")
    .last()
    .remove();
});
$(".multiple").on("click", ".add-more", function () {
  $(".add-more")
    .closest(".multiple")
    .find(".custom-file")
    .first()
    .clone()
    .appendTo(".result");
});
$("#clone2").click(function () {
  $(".multiple2").append($(".clone2").clone());
});
$("#delete2").click(function () {
  $(this)
    .parent()
    .addClass("asd");
});

// datepicker
// $("#datepicker").datepicker({
//     format:"yyyy-mm-dd",
//     autoclose: true
// });

// account
function menu1(id) {
  if (id === 1) {
    $("#content1").addClass("active");
    $("#content2").removeClass("active");
    $("#menu1").addClass("active");
    $("#menu2").removeClass("active");
  } else {
    $("#content2").addClass("active");
    $("#content1").removeClass("active");
    $("#menu2").addClass("active");
    $("#menu1").removeClass("active");
  }
}

// carousel product detail autoplay false
// $('#slider02').carousel({ interval: false })

// qty product detail
// $(document).ready(function() {
//   $(".count").prop("disabled", true);
//   $(document).on("click", ".plus", function() {
//     $(".count").val(parseInt($(".count").val()) + 1);
//   });
//   $(document).on("click", ".minus", function() {
//     $(".count").val(parseInt($(".count").val()) - 1);
//     if ($(".count").val() === 0) {
//       $(".count").val(1);
//     }
//   });
// });
export { menu1, menu };
