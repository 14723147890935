import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux"
import { sosial } from './data'
import Payment from '../../../assets/img/payment/payment.webp'
import Payment1 from '../../../assets/img/payment/es.webp'
import appstore from "../../../assets/img/app-store.png";
import googleplay from "../../../assets/img/google-play.png";
import HomePageFooterContent from "./HomePageFooterContent";
// import _ from "lodash";
import "./Footer.scss";


class Footer extends Component {
  handlePage = (id) => {
    let { pages } = this.props
    const page = (pages && pages.find(f => f.SpreeSinglePagesCategory.category_name === id)) || {}
    return page.permalink || ''
  }
  // handlePath = (pathName) => {
  //   this.props.history.push("/" + pathName)
  // }
  render() {
    let { children } = this.props;
    return (
      <footer className="mt-4">
        {this.props.location.pathname === "/" && <HomePageFooterContent />}
        {children && children}
        <div className="container">
          <div className="level footer">
            <div className="level-item">
              <div className="support">
                <div className="title">BUYER SOLUTION</div>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>B2B	&amp; B2C Marketplace
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Business Matching
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Express Courrier
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Freight Forwarder
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Internasional Dropship
                </NavLink>
                <NavLink className='list' to='#' >
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Invoice Financing
                </NavLink>
                <div className="title mt-3">SELLER SOLUTION</div>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>B2B &amp; B2C Marketplace
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Business Matching
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Ekspor Barengan
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>E- Learning
                </NavLink>
                <NavLink className="list" to='#'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Express Courrier
                </NavLink>
                <NavLink className='list' to='#' >
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Export Financing
                </NavLink>
                <NavLink className='list' to='#' >
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Freight Forwarder
                </NavLink>
                <NavLink className='list' to='#' >
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Internasional Dropship
                </NavLink>
                <NavLink className='list' to='#' >
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>International Exhibition
                </NavLink>
                <NavLink className='list' to='#' >
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>International Product Certification
                </NavLink>
              </div>
            </div>
            <div className="level-item">
              <div className="support">
                <div className="title">COMMUNITY</div>
                <NavLink className="list" to='/about'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>About Us
                </NavLink>
                {/* <NavLink className="list" to="#">
                  Blog
                </NavLink> */}
                <NavLink className="list" to="/blogs">
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Blog
                </NavLink>
                {/* <NavLink className="list" to="#">
                  Syarat &amp; Ketentuan
                </NavLink> */}
                <NavLink className="list" to='/career'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Career
                </NavLink>
                {/* <NavLink className="list" to='/term-and-condition'>
                  Terms & Conditions
                </NavLink> */}
                <NavLink className="list" to='/contact'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Contact Us
                </NavLink>
                <NavLink className="list" to='/faq'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>FAQ
                </NavLink>
                <NavLink className="list" to='/agency'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Merchant
                </NavLink>
                <NavLink className="list" to='/sitemap'>
                  <span className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span>Sitemap
                </NavLink>

                {/* <NavLink className="list" to='/partner-program'>
                  Partner Program
                </NavLink>
                <NavLink className="list" to='/fda-registration'>
                  FDA Registration
                </NavLink> */}
                {/* <NavLink className="list" to="#">
                  Tagihan
                </NavLink> */}
              </div>
            </div>
            <div className="level-item">
              <div className="support">
                <div className="title">DOWNLOAD OUR APPS</div>
                <div className="row">
                  <div className="col-sm">
                    <a className="list" target='_blank' href="https://apps.apple.com/id/app/aspenku/id1534126166" rel="noopener noreferrer">
                      <img src={appstore} alt="App Store" />
                    </a>
                  </div>
                  <div className="col-sm">
                    <a className="list" target='_blank' href="https://play.google.com/store/apps/details?id=com.app.totalit.aspenku" rel="noopener noreferrer">
                      <img src={googleplay} alt="Google Play" />
                    </a>
                  </div>
                </div>

                <div className="title mt-3">FOLLOW US</div>
                {sosial.map((item) => {
                  return (
                    <a key={item.id} target='_blank' href={item.path} rel="noopener noreferrer" >
                      <img src={item.img} alt=""></img>
                    </a>

                  );
                })}
              </div>
            </div>
            <div className="level-item">
              <div className="accepted-payment">
                <div className="title">ACCEPTED PAYMENT</div>

                <img style={{ width: "450px" }} src={Payment} className="img-fluid" alt="Payment" />
                <div className="title">SHIPPING</div>
                <img style={{ width: "450px", marginBottom: "30px" }} src={Payment1} className="img-fluid" alt="Payment" />
                <div className="title"><NavLink className="list" to='/term-and-condition'>
                  TERM AND CONDITIONS
                </NavLink></div>
                {/* <div className="row">
                  <div className="col">
                  <div className="title">PRIVACY POLICY</div>
                  </div>
                  <div className="col">
                  <div className="title"><NavLink className="list" to='/term-and-condition'>
                  TERM AND CONDITIONS
                </NavLink></div>
                  </div>
                </div> */}
                {/* <div className="list">
                2018-2022–All Right Reserved By Aspenku–PT Aplikasi Penunjang Ekonomi Nasional
                </div> */}
                <div className="list">
                  Copyright Aspenku v1.3.0 . All Right Reserved
                </div>
              </div>

            </div>
            
          </div>
        </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  pages: state.pages.pages

})
export default withRouter(connect(mapStateToProps, {})(Footer));
