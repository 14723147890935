import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ProductCard from "./ProductCard/ProductCard";
// import defaultProductImage from "../../assets/img/default_product_image.png";
import Loader from "./LoaderSpinner/LoaderSpinner";
import _ from "lodash";
export default class PaginationProductCard extends Component {
  state = {
    skip: 0,
    totalPage: [],
    totalRow: "",
    isPageActive: 1,
    pages: [],
    lastPageNo: "",
    isLoading: false
  };

  componentDidMount = () => {
    let total = this.props.totalRow;
    this.setState(
      {
        totalRow: total,
        isLoading: false
      },
      () => {
        this.countPages(total);
      }
    );
  };

  componentWillReceiveProps = nextProps => {
    let total;
    if (this.props !== nextProps) {
      if (this.props.subCategoryId !== nextProps.subCategoryId) {
        this.setState({ isPageActive: 1 });
      }
      if (this.props.filters !== nextProps.filters) {
        this.setState({ isPageActive: 1 });
      }
      if (this.props.sortBy !== nextProps.sortBy) {
        this.setState({ isPageActive: 1 });
      }
      total = nextProps.totalRow;
      this.resetState();
      this.setState(
        {
          totalRow: total,
          isLoading: false
        },
        () => {
          this.countPages(total);
        }
      );
    }
  };

  countPages = total => {
    let limit = this.props.limit;
    let { totalPage, pages } = this.state;
    let page = Math.ceil(total / limit);
    for (let i = 0; i < page; i++) {
      totalPage.push(i);
    }
    if (page > 6) {
      for (let i = 0; i < 6; i++) {
        pages.push(i);
      }
    }
    this.setState({ totalPage: totalPage });
  };

  handleLimitAndSkip = index => {
    this.setState({ skip: 0, isPageActive: index, isLoading: true });
    let limit = this.props.limit;
    let data = {
      subCategoryId: this.props.subCategoryId,
      subCategoryName: this.props.subCategoryName,
      isActive: this.props.isActive
    };
    // let cnt = index === 1 ? 0 : (index - 1) * 10;
    let cnt = index === 1 ? 0 : (index - 1) * limit;
    this.setState({ skip: cnt }, () => {
      this.props.changeLimitAndSkip(this.state.skip, data);
    });
  };

  handleNextPage = () => {
    this.setState({ isLoading: true });
    let limit = this.props.limit;
    let data = {
      subCategoryId: this.props.subCategoryId,
      subCategoryName: this.props.subCategoryName
    };
    let { skip, isPageActive } = this.state;
    let nextPageNo = isPageActive + 1;
    let newSkip = skip + limit;
    this.setState({ skip: newSkip, isPageActive: nextPageNo }, () => {
      this.props.changeLimitAndSkip(this.state.skip, data);
    });
  };

  handlePreviousPage = () => {
    this.setState({ isLoading: true });
    let limit = this.props.limit;
    let data = {
      subCategoryId: this.props.subCategoryId,
      subCategoryName: this.props.subCategoryName
    };
    let { skip, isPageActive } = this.state;
    let prevPageNo = isPageActive - 1;
    let newSkip = skip - limit;
    this.setState({ skip: newSkip, isPageActive: prevPageNo }, () => {
      this.props.changeLimitAndSkip(this.state.skip, data);
    });
  };

  resetState = () => {
    this.setState({
      totalPage: [],
      totalRow: "",
      pages: []
    });
  };

  render() {
    let { totalPage, isPageActive, pages, isLoading } = this.state;
    let { products, className, productCardContainerClassName } = this.props;
    // let imageUrl = defaultProductImage,
    //   salePrice = "",
    let counter = [];
    totalPage.length > 6 ? (counter = pages) : (counter = totalPage);

    return (
      <>
        <div className={"row product-single " + className}>
          {isLoading ? (
            <span className="d-flex align-items-center justify-content-center w-100">
              <Loader
                width={50}
                height={50}
                color="gray"
                borderWidth={3}
                animationDuration={"0.8s"}
              />
            </span>
          ) : (
              <>
                {products.length > 0 &&
                  products.map((product, index) => {
                    if (!_.isEmpty(product)) {
                      if(!this.props.manageProduct && product.is_moderated === 0) {
                        return ''
                      }

                      return (
                        <div
                          className={
                            "col-md-4 col-lg-2 col-sm-4 cols " +
                            (productCardContainerClassName
                              ? productCardContainerClassName
                              : "")
                          }
                          key={index}
                        >
                          <ProductCard
                            index={index}
                            product={product}
                            imgClassName="w-100"
                            // imageUrl={imageUrl}
                            // productId={product.id}
                            // productName={product.name}
                            // SpreeStore={SpreeStore}
                            // salePrice={salePrice}
                            // productState={product.product_state}
                            {...this.props}
                          />
                        </div>
                      );
                    }
                    return true;
                  })}
              </>
            )}
        </div>

        {totalPage.length > 1 ? (
          <nav aria-label="container navigation">
            <ul className="row pagination justify-content-center">
              {isPageActive > 6 && (<>
                <li className="page-item">
                  <NavLink
                    className="page-link"
                    to="#"
                    aria-label="Previous"
                    onClick={this.handlePreviousPage}
                  >
                    <span className="pag-prev" />
                  </NavLink>
                </li>
                <li className={"page-item"}>
                  <NavLink
                    className="page-link"
                    to="#"
                    onClick={() => this.handleLimitAndSkip(totalPage[1])}
                  >
                    {totalPage[1]}
                  </NavLink>
                </li>
                <li className={"page-item"}>
                  <NavLink
                    className="page-link"
                    to="#"
                  // onClick={this.handleNextPage}
                  >
                    {".."}
                  </NavLink>
                </li>
              </>)}              
              {counter.map((page, index) => {
                let pageNo =
                  isPageActive > 6 ? isPageActive - (4 - index) : index + 1;
                let classname = isPageActive === pageNo ? "active" : "";
                if(pageNo > totalPage.length) return ''
                return (
                  <li className={"page-item " + classname} key={index}>
                    <NavLink
                      className="page-link"
                      to="#"
                      onClick={() => this.handleLimitAndSkip(pageNo)}
                    >
                      {pageNo}
                    </NavLink>
                  </li>
                );
              })}
              {/* <li className={"page-item "}>
                <NavLink
                  className="page-link border-0"
                  to="#"
                >
                  ...
                  </NavLink>
              </li>
              <li className={"page-item "}>
                  <NavLink
                    className="page-link"
                    to="#"
                  >
                  {totalPage[totalPage.length - 1]}
                  </NavLink>
              </li> */}
              {totalPage.length > 6 &&  (totalPage.length - isPageActive) > 2 && (
                <>
                  <li className={"page-item"}>
                    <NavLink
                      className="page-link"
                      to="#"
                      // onClick={this.handleNextPage}
                    >
                      {".."}
                    </NavLink>
                  </li>
                  <li className={"page-item"}>
                    <NavLink
                      className="page-link"
                      to="#"
                      onClick={() => this.handleLimitAndSkip(totalPage.length)}
                    >
                      {totalPage.length}
                    </NavLink>
                  </li>
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to="#"
                      aria-label="Next"
                      onClick={this.handleNextPage}
                    >
                      <span className="pag-next" />
                    </NavLink>
                  </li>
                </>
              )}
              {totalPage.length > 6 &&  (totalPage.length - isPageActive) === 2 && (<>
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to="#"
                      aria-label="Next"
                      onClick={this.handleNextPage}
                    >
                      <span className="pag-next" />
                    </NavLink>
                  </li>
              </>)}
            </ul>
          </nav>
        ) : (
            <nav aria-label="navigation">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <NavLink
                    className="page-link"
                    to="#"
                    aria-label="Previous"
                    disabled
                  >
                    <span className="pag-prev" />
                  </NavLink>
                </li>
                <li className="page-item disabled">
                  <NavLink
                    className="page-link"
                    to="#"
                    aria-label="Next"
                    disabled
                  >
                    <span className="pag-next" />
                  </NavLink>
                </li>
              </ul>
            </nav>
          )}
      </>
    );
  }
}
