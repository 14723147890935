import { STORE, SELECTED_MENU_STORE , SET_USER_STORE } from "../actions/typesOfActions";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const persistConfig = {
    key: 'store',
    storage,
    whitelist: ['selectedMenu' ]
}
const intialState = {
    storeInfo: {},
    userStore: {},
    selectedMenu: 'main_profile'
};

export default persistReducer(persistConfig, (state = intialState, action) => {
    switch (action.type) {
        case STORE:
            return {
                ...state,
                storeInfo: action.data || {}
            };
        case SET_USER_STORE:
                return {
                    ...state,
                    userStore: action.userStore || {}
                }
        case SELECTED_MENU_STORE:
            return {
                ...state,
                selectedMenu: action.selectedMenu
            }
        default:
            return state;
    }
})
