export const createGroupChannel = payload => (dispatch, getState, Api) => {
    return Api.post("sendbird/createGroupChannel", payload).then(response => {
        return response;
    });
};
export const getAllChannel = id => (dispatch, getState, Api) => {
    return Api.get("sendbird/groupChannel/" + id).then(response => {
        return response;
    });
};
export const getCountOfUnreadMessage = id => (dispatch, getState, Api) => {
    return Api.get("sendbird/groupChannel/messages/" + id).then(response => {
        if (response.statusCode === 200) {
            if (JSON.parse(localStorage.getItem("notification"))) {
                // localStorage.removeItem("notification");
                localStorage.setItem("notification", JSON.stringify(response.data))
            }
            else {
                localStorage.setItem("notification", response.data)
            }

        }
        return response;
    });
};