import Axios from 'axios'
import global from '../global'

import { createHeaders } from './headerAPI'

export const postStoreProfilingAPI = (payload ) => {
    return Axios({
        method : 'POST',
        data : payload,
        headers : createHeaders() ,
        url : global.config.apiUrlV3 + 'user/store/profiling',
    })
}

export const getStoreProfilingAPI = () => {
    return Axios({
        method : 'GET',
        headers : createHeaders() ,
        url : global.config.apiUrlV3 + 'user/store/profiling',
    })
}

export const putStoreProfilingAPI = (payload ) => {
    return Axios({
        method : 'PUT',
        data : payload,
        headers : createHeaders() ,
        url : global.config.apiUrlV3 + 'user/store/profiling',
    })
}

export const postContactUsAPI = (payload ) => {
    return Axios({
        method : 'POST',
        data : payload,
        headers : createHeaders() ,
        url : global.config.apiUrlV3 + 'users/store/contactUs',
    })
}

export const uploadImageStore = (payload ) => {
    return Axios({
        method : 'POST',
        data : payload,
        headers : createHeaders() ,
        url : global.config.apiUrlV3 + 'store/images',
    })
}
