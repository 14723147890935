export const checkPermalink = payload => (dispatch, getState, Api) => {
  return Api.post("permalink/check", payload).then(response => {
    return response;
  });
};
export const addProduct = payload => (dispatch, getState, Api) => {
  return Api.post("users/products", payload, null, "v3").then(response => {
    return response;
  });
};
export const editProduct = (payload, productId) => (
  dispatch,
  getState,
  Api
) => {
  return Api.put("users/products/" + productId, payload, null, "v3").then(
    response => {
      return response;
    }
  );
};
export const deleteProduct = productId => (dispatch, getState, Api) => {
  return Api.delete("users/products/" + productId).then(response => {
    return response;
  });
};
export const getSalesOrders = payload => (dispatch, getState, Api) => {
  let apiUrl = "users/salesOrder?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.order_state) {
    apiUrl += `&order_state=${payload.order_state}`;
  }
  return Api.get(
    apiUrl, null, "v3"
  ).then(response => {
    return response;
  });
};
export const addShippingFee = (payload, orderId) => (
  dispatch,
  getState,
  Api
) => {
  return Api.post("users/orders/" + orderId + "/shippingFee", payload).then(
    response => {
      return response;
    }
  );
};
export const getSalesOrderDetail = orderId => (dispatch, getState, Api) => {
  return Api.get("users/salesOrder/" + orderId, null, "v3").then(response => {
    return response;
  });
};
export const changeProductState = payload => (dispatch, getState, Api) => {
  return Api.put("users/products/publish", payload).then(response => {
    return response;
  });
};
export const changeOrderStatus = (payload, orderId) => (
  dispatch,
  getState,
  Api
) => {
  return Api.put("users/salesOrders/" + orderId + "/status", payload).then(
    response => {
      return response;
    }
  );
};
export const addTrackingNumber = (payload, orderId) => (
  dispatch,
  getState,
  Api
) => {
  return Api.post(
    "users/salesOrders/" + orderId + "/trackingNumber",
    payload
  ).then(response => {
    return response;
  });
};
export const cancelSalesOrder = (payload, id) => (dispatch, getState, Api) => {
  return Api.put("users/salesOrder/" + id + "/cancel", payload).then(
    response => {
      return response;
    }
  );
};
export const handleRefund = (payload, id) => (dispatch, getState, Api) => {
  return Api.put("users/salesOrders/" + id + "/refund", payload).then(
    response => {
      return response;
    }
  );
};

export const handleReturnReceived = id => (dispatch, getState, Api) => {
  return Api.put("users/salesOrders/" + id + "/receivedReturn").then(
    response => {
      return response;
    }
  );
};

export const generateJNEWaybill = orderId => (dispatch, getState, Api) => {
  return Api.post("store/shipment/JNE/waybill/generate/" + orderId, {}, null, "v3").then(response => {
    return response;
  })
}

export const generateDHLWaybill = orderId => (dispatch, getState, Api) => {
  return Api.get("shipment/dhl/awb/" + orderId, null, "v3").then(response => {
    return response;
  })
}

export const generateDHLpickup = orderId => (dispatch, getState, Api) => {
  return Api.get("shipment/dhl/pickup/" + orderId, null, "v3").then(response => {
    return response;
  })
}

export const generateJNTWaybill = orderId => (dispatch, getState, Api) => {
  return Api.get("shipment/jnt/waybill/" + orderId, null, "v3").then(response => {
    return response;
  })
}

export const processShipping = id => (dispatch, getState, Api) => {
  return Api.post(`users/salesOrders/${id}/processShipping`, {}, null, "v3").then(
    response => {
      return response
    }
  )
}

export const importProducts = (file) => (dispatch, getState, Api) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", "productList");
  return Api.post("bulk/uploadProduct", formData, "formData", "v3").then(response => {
    return response;
  });
}