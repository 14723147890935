import React, { Component } from 'react'
import find from "../../../../assets/img/find.svg";
import "./SearchBarForResponsiveView.scss";
export default class SearchBarForResponsiveView extends Component {
    render() {
        let { searchText } = this.props
        return (
            <div className="responsive-search">
                <div className="level-item is-fullwidth is-relative main-search">
                    <div style={{ width: "100%" }}>
                        <input
                            type="text"
                            className="form-control search-header"
                            id="responsive-search"
                            placeholder="What are you looking for?"
                            onChange={e => this.props.handleSearchInputChange(e)}
                            onKeyPress={e => {
                                e.key === "Enter" &&
                                    searchText &&
                                    this.props.handleSearchPage();
                            }}
                        />
                        <span className="icon-search">
                            <img
                                src={find}
                                alt="search"
                                onClick={() => {
                                    searchText && this.props.handleSearchPage();
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
