import { combineReducers } from "redux";
import login from "./login";
import { LOGOUT } from "../actions/typesOfActions";
import categories from "./category.reducer";
import subCategory from "./subCategory.reducer";
import store from "./store.reducer";
import cart from "./cart.reducer";
import product from "./product.reducer";
import exchangeRate from "./exchangeRate.reducer";
import pages from "./pages.reducer"
const appReducer = combineReducers({
  login,
  categories,
  subCategory,
  store,
  cart,
  product,
  exchangeRate,
  pages
});
// export default appReducer;

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = {
      ...state,
      store: { storeInfo: {} },
      login: { user: {} },
      product: { wishlistProducts: [] },
      cart: { cartInfo: [] }
    };
    // state = {
    //   ...state.login,
    //   user: null
    // };
  }
  return appReducer(state, action);
};

export default rootReducer;
