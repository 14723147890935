export const getBuyerDocuments = () => (dispatch, getState, Api) => {
    return Api.get("users/documents").then(response => {
        return response;
    });
};
export const verifyBuyer = payload => (dispatch, getState, Api) => {
    return Api.post("users/documents", payload).then(response => {
        return response;
    });
};

export const reVerifyBuyer = payload => (dispatch, getState, Api) => {
    return Api.put("users/documents", payload).then(response => {
        return response;
    });
};