import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "./SweetAlertBox.scss";
class SweetAlertBox extends Component {
  render() {
    let {
      title,
      message,
      showCancel,
      confirmBtnText,
      type,
      cancelBtnText,
      reverseButtons,
      children
    } = this.props;
    return (
      <SweetAlert
        custom
        title={title}
        type={type ? type : "warning"}
        showCancel={showCancel}
        // showCloseButton
        closeOnClickOutside={false}
        confirmBtnText={confirmBtnText ? confirmBtnText : "Yes"}
        cancelBtnText={cancelBtnText ? cancelBtnText : "No"}
        confirmBtnCssClass="buttons confirm-button"
        cancelBtnCssClass="buttons cancel-button"
        reverseButtons={reverseButtons === undefined ? true : reverseButtons }
        onConfirm={this.props.onConfirm}
        onCancel={this.props.onCancel}
      >
        {children || message }
      </SweetAlert>
    );
  }
}
export default SweetAlertBox;
