export const getTenders = (payload) => (dispatch, getState, Api) => {
    let apiUrl = "tenders?limit=" + payload.limit + "&skip=" + payload.skip + "&type=1";
    // let apiUrl = "tenders?limit=" + payload.limit + "&skip=" + payload.skip;
    if (payload.status) {
        apiUrl = apiUrl + "&status=" + payload.status
    }
    if (payload.closing_soon) {
        apiUrl = apiUrl + "&closing_soon=" + payload.closing_soon
    }
    if (payload.searchText) {
        apiUrl = apiUrl + "&search=" + payload.searchText
    }
    return Api.get(apiUrl).then(response => {
        return response;
    });
};

export const getUserTenders = payload => (dispatch, getState, Api) => {
    return Api.get("users/tenders?limit=" + payload.limit + "&skip=" + payload.skip).then(response => {
        return response;
    });
};

export const getUserBids = payload => (dispatch, getState, Api) => {
    return Api.get("users/quotation?limit=" + payload.limit + "&skip=" + payload.skip).then(response => {
        return response;
    });
};

export const getTenderDetails = payload => (dispatch, getState, Api) => {
    let apiUrl = "tenders/" + payload.id;
    if (payload.type) {
        apiUrl = apiUrl + "?type=" + payload.type
    }
    return Api.get(apiUrl).then(response => {
        return response;
    });
};

export const getBidDetails = payload => (dispatch, getState, Api) => {
    let apiUrl = "users/quotation/" + payload.id;
    return Api.get(apiUrl).then(response => {
        return response;
    });
};

export const openTender = payload => (dispatch, getState, Api) => {
    return Api.post("users/tenders", payload).then(response => {
        return response;
    });
};

export const getUserRFQs = payload => (dispatch, getState, Api) => {
    return Api.get("users/rfq?limit=" + payload.limit + "&skip=" + payload.skip).then(response => {
        return response;
    });
};

export const createRFQ = payload => (dispatch, getState, Api) => {
    return Api.post("users/rfq", payload).then(response => {
        return response;
    });
};

export const addQuotation = payload => (dispatch, getState, Api) => {
    return Api.post("tenders/quotations", payload).then(response => {
        return response;
    });
};
export const setWinner = (params) => (dispatch, getState, Api) => {
    return Api.put("users/tenders/" + params.tender_id + "?vendor_id=" + params.vendor_id + "&quotation_id=" +
        params.quotation_id).then(response => {
            return response;
        });
};
export const getProductsForBidForm = payload => (dispatch, getState, Api) => {
    return Api.get(
        "product?category=" + payload.category + "&subCategory="
        + payload.subCategory + "&store_id=" + payload.store_id
        + "&product_state=published"
    ).then(response => {
        return response;
    });
};
export const createPurchaseOrder = payload => (dispatch, getState, Api) => {
    return Api.post("purchaseOrder", payload, null, "v2").then(response => {
        return response;
    });
};
export const editQuotation = (payload, bidId) => (dispatch, getState, Api) => {
    return Api.put("tenders/quotations/" + bidId, payload).then(response => {
        return response;
    });
};
export const deleteQuotation = (bidId) => (dispatch, getState, Api) => {
    return Api.delete("tenders/quotations/" + bidId).then(response => {
        return response;
    });
};