import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as firebase from "firebase/app";
import "firebase/auth";
import _ from "lodash";
import global from "../../global";
import { loginApi } from "../../actions";
import google from "../../assets/img/google.svg";
// import fb from "../../assets/img/fb.svg";
import Modal from "../reusable/Modal";
import SocialLogin from "./SocialLogin";
import MessageAlert from "../reusable/MessageAlert";
import Loader from "../reusable/LoaderSpinner";
import TextInput from "../reusable/FormControls/TextInput";
import "./Login.scss";
global.firebaseInitialization();
class Login extends Component {
  constructor(props) {
    super(props);
    this.delayedCallback = _.debounce(this.closeToggleForms, 3000);
  }
  static contextType = global.loaderContext;
  state = {
    formData: {
      login_Email: "",
      password: "",
      reset_password_email: "",
    },
    reset_password_email: "",
    formErrors: {},
    showPassword: false,
    alertStyle: "",
    errorMessage: "",
    isLoading: false,
    showResetPasswordForm: false,
    fbLoader: false,
    googleLoader: false,
    isEmailVerified: true,
  };
  componentDidMount = () => {
    if (this.context) {
      this.context.hideLoader();
    }
    this.setState({ fbLoader: false, googleLoader: false });
  };
  handleChange = (e) => {
    let element_Name = e.target.name;
    let element_Value = e.target.value;
    let value = true;
    if (
      element_Name === "login_Email" ||
      element_Name === "reset_password_email"
    ) {
      value = element_Value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    } else if (element_Name === "password") {
      value = !element_Value.match(/\s/g);
    }
    if (value) {
      this.setState({
        formData: { ...this.state.formData, [element_Name]: element_Value },
        formErrors: { ...this.state.formErrors, [element_Name]: false },
      });
    } else {
      this.setState({
        formData: { ...this.state.formData, [element_Name]: element_Value },
        formErrors: { ...this.state.formErrors, [element_Name]: true },
      });
    }
  };

  handlePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    let { login_Email, password } = this.state.formData;
    firebase
      .auth()
      .signInWithEmailAndPassword(login_Email, password)
      .then(async (response) => {
        if (response.user.emailVerified) {
          let uid = response.user.uid;
          let getUserInfo = await firebase.auth().currentUser;
          let authentication_Token = await getUserInfo.getIdToken();
          this.handleSubmit(uid, authentication_Token);
        } else {
          this.setState({
            isEmailVerified: false,
            isLoading: false,
            alertStyle: "danger",
            errorMessage:
              "Confirmation mail was already sent to your registered email. Please verify your email and login.",
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        if (
          error.code === "auth/wrong-password" ||
          error.code === "auth/user-not-found" ||
          error.code === "auth/invalid-email"
        ) {
          this.setState({
            alertStyle: "danger",
            errorMessage: error.message,
          });
        }
      });
  };

  handleSubmit = (uid, authentication_Token) => {
    let payload = {
      uid: uid,
      authentication_token: authentication_Token,
    };
    this.props.loginApi(payload).then((response) => {
      if (response.statusCode === 200) {        
        if (global.getAuthToken()) {
          if (response.data.userType === "User") {
            let currentPath = this.props.history.location.pathname;
            currentPath === "/register"
              ? this.props.history.push("/")
              : this.props.hideModal();
          } else if (response.data.userType === "Admin") {
            window.open(
              global.config.adminLoginPageUrl + global.getAuthToken(),
              "_self"
            );
          }
        }
        this.actionGtag()
      } else {
        this.setState({
          errorMessage:
            response.statusCode === 400
              ? global.errors.error400
              : response.message,
          alertStyle: "danger",
        });
      }
      this.setState({
        isLoading: false,
      });
    });
  };
  resendVerificationEmail = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    let { login_Email, password } = this.state.formData;
    let actionCodeSettings = {
      url: global.config.homePageUrl,
    };
    firebase
      .auth()
      .signInWithEmailAndPassword(login_Email, password)
      .then(async (response) => {
        let getUserInfo = await firebase.auth().currentUser;
        getUserInfo
          .sendEmailVerification(actionCodeSettings)
          .then((res) => {
            this.setState(
              {
                formData: {
                  login_Email: "",
                  password: "",
                  reset_password_email: "",
                },
                isLoading: false,
                alertStyle: "success",
                errorMessage:
                  "Confirmation mail sent to your registered email. Please verify your email and login.",
              },
              () => {
                this.delayedCallback();
              }
            );
          })
          .catch((error) => {
            this.setState({
              isDisabled: false,
              isLoading: false,
              alertStyle: "danger",
              errorMessage: "Error while sending verification email.",
            });
          });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        if (
          error.code === "auth/wrong-password" ||
          error.code === "auth/user-not-found" ||
          error.code === "auth/invalid-email"
        ) {
          this.setState({
            alertStyle: "danger",
            errorMessage: error.message,
          });
        }
      });
  };
  handleAlertClose = () => {
    this.setState({
      alertStyle: "",
      errorMessage: "",
    });
  };

  toggleForms = () => {
    this.setState(
      {
        showResetPasswordForm: !this.state.showResetPasswordForm,
        isLoading: false,
      },
      () => {
        let { showResetPasswordForm } = this.state;
        if (!showResetPasswordForm) {
          this.setState({
            formData: { ...this.state.formData, reset_password_email: "" },
            formErrors: { ...this.state.formErrors, reset_password_email: "" },
          });
        }
      }
    );
  };

  sendRecoverPasswordEmail = (e) => {
    let { formData } = this.state;
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    let actionCodeSettings = {
      url: global.config.homePageUrl,
    };
    firebase
      .auth()
      .sendPasswordResetEmail(formData.reset_password_email, actionCodeSettings)
      .then((res) => {
        this.setState(
          {
            isLoading: false,
            alertStyle: "success",
            errorMessage: `Password link sent successfully, Please check email on ${formData.reset_password_email} .`,
          },
          () => {
            this.delayedCallback();
            // this.props.hideModal();
            // this.props.history.push("/");
          }
        );
      })
      .catch((error) => {
        // if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found" || error.code === "auth/invalid-email") {
        this.setState({
          isLoading: false,
          alertStyle: "danger",
          errorMessage: error.message,
        });
        // }
      });
  };
  closeToggleForms = () => {
    this.props.hideModal();
    this.props.history.push("/");
  };
  setErrorMessage = (alertStyle, message) => {
    this.setState({
      alertStyle: alertStyle,
      errorMessage: message,
    });
  };
  setLoader = (type) => {
    if (type === "facebook") {
      this.setState({ fbLoader: !this.state.fbLoader });
    } else if (type === "google") {
      this.setState({ googleLoader: !this.state.googleLoader });
    } else {
      this.setState({
        googleLoader: false,
        fbLoader: false,
      });
    }
  };
  actionGtag = () => {
    window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-613539965/5thVCI6pzYkCEP3Ax6QC'});
  }
  render() {
    let {
      showPassword,
      formData,
      alertStyle,
      errorMessage,
      isLoading,
      showResetPasswordForm,
      formErrors,
      // fbLoader,
      googleLoader,
      isEmailVerified,
    } = this.state;
    let disabled =
      formData.login_Email && formData.password && !isLoading ? "" : "disabled";

    return (
      <Modal
        id="loginID"
        title={showResetPasswordForm ? "Reset password" : "Login"}
        className=""
        hideModal={this.props.hideModal}
      >
        {errorMessage ? (
          <MessageAlert
            alertType={alertStyle}
            message={errorMessage}
            handleAlertClose={this.handleAlertClose}
          />
        ) : (
          ""
        )}
        {showResetPasswordForm ? (
          <>
            <div
              className="c-pointer mb-3"
              onClick={() => {
                this.toggleForms();
              }}
            >
              <i className="fa fa-arrow-left"></i> Back
            </div>
            <form className="form-horizontal">
              <div className="form-group">
                <TextInput
                  type="email"
                  placeholder="Email address"
                  name="reset_password_email"
                  value={formData.reset_password_email}
                  onChange={this.handleChange}
                />
                {formErrors.reset_password_email && (
                  <div className="form-group">
                    <span className="help-block">
                      Please enter valid email.
                    </span>
                  </div>
                )}
              </div>

              <div className="form-group text-center m-t-20">
                <div className="col-xs-12">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={
                      formData.reset_password_email &&
                      !formErrors.reset_password_email
                        ? ""
                        : "disabled"
                    }
                    onClick={(e) => this.sendRecoverPasswordEmail(e)}
                  >
                    {isLoading ? (
                      <span className="d-flex align-items-center justify-content-center">
                        <Loader
                          width={18}
                          height={18}
                          borderWidth={2}
                          color="#bbb4b4"
                        />
                        &nbsp;Send Reset Link
                      </span>
                    ) : (
                      "Send Reset Link"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <div className="login">
            <form>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email Address"
                  onChange={(e) => this.handleChange(e)}
                  name="login_Email"
                  value={formData.login_Email}
                  autoComplete="email"
                />
                {/* {formErrors.login_Email && (
                    <div className="form-group">
                      <span className="help-block">Please enter valid email.</span>
                    </div>
                  )} */}
              </div>
              <div className="form-group is-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={(e) => this.handleChange(e)}
                  name="password"
                  value={formData.password}
                  autoComplete="off"
                />
                <span
                  className={
                    showPassword
                      ? "fa fa-eye show-pass "
                      : "fa fa-eye-slash  show-pass"
                  }
                  toggle="#password-field"
                  onClick={() => this.handlePassword()}
                />
                {/* {formErrors.password && (
                    <div className="form-group">
                      <span className="help-block">White space is not allowed in password.</span>
                    </div>
                  )} */}
              </div>
              {isEmailVerified ? (
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={disabled}
                  onClick={(e) => this.handleLogin(e)}
                >
                  {isLoading ? (
                    <span className="d-flex align-items-center justify-content-center">
                      <Loader
                        width={18}
                        height={18}
                        borderWidth={2}
                        color="#bbb4b4"
                      />
                      &nbsp;Sign in
                    </span>
                  ) : (
                    "Sign in"
                  )}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={disabled}
                  onClick={(e) => this.resendVerificationEmail(e)}
                >
                  {isLoading ? (
                    <span className="d-flex align-items-center justify-content-center">
                      <Loader
                        width={18}
                        height={18}
                        borderWidth={2}
                        color="#bbb4b4"
                      />
                      &nbsp;Resend Verification Email
                    </span>
                  ) : (
                    "Resend Verification Email"
                  )}
                </button>
              )}
            </form>
            <div className="mx-3">
              <div className="no-account text-left">
                <div className="row">
                  <div className="col-7">
                    <span>Don’t have an account?</span>
                  </div>
                  <div className="col-5">
                    <NavLink
                      to="/register"
                      onClick={this.props.hideModal}
                      className="link-register ml-0"
                    >
                      Register here!
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="no-account text-left mt-3">
                <div className="row">
                  <div className="col-7">
                    <span>Forgot Password?</span>
                  </div>
                  <div className="col-5">
                    <NavLink
                      to="#"
                      onClick={this.toggleForms}
                      className="link-register ml-0"
                    >
                      Reset here!
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="is-divider" data-content="or login with"></div>
            <div className="box-login">
              <SocialLogin
                hideModal={this.props.hideModal}
                setErrorMessage={this.setErrorMessage}
                setLoaderAndDisable={this.setLoader}
                googleDesign={(renderProps) => (
                  <button
                    type="button"
                    className="btn btn-outline-primary login-with w-100"                    
                    onClick={(e) => {
                      this.actionGtag()
                      renderProps.onClick(e)
                    }}
                    disabled={renderProps.disabled}
                  >
                    <img src={google} alt="google" className="float-left" />
                    {googleLoader ? (
                      <span className="d-flex align-items-center justify-content-center">
                        <Loader
                          width={18}
                          height={18}
                          borderWidth={2}
                          color="#454c53"
                        />
                        &nbsp;Google
                      </span>
                    ) : (
                      "Google"
                    )}
                  </button>
                )}
                facebookDesign={(renderProps) => {
                  return null;
                  // <button
                  //   type="button"
                  //   className="btn btn-outline-primary login-with w-100"
                  //   onClick={(e) => {
                  //     this.actionGtag()
                  //     renderProps.onClick(e)
                  //   }}
                  //   disabled={renderProps.isDisabled}
                  // >
                  //   <img src={fb} alt="facebook" className="float-left" />
                  //   {fbLoader ? (
                  //     <span className="d-flex align-items-center justify-content-center">
                  //       <Loader
                  //         width={18}
                  //         height={18}
                  //         borderWidth={2}
                  //         color="#454c53"
                  //       />
                  //       &nbsp;Facebook
                  //     </span>
                  //   ) : (
                  //     "Facebook"
                  //   )}
                  // </button>
                }}
              />
            </div>
            <div className="display-message">
              Currenctly the Login by Facebook & Sign in by Google can only work
              for Android users. iOS users should register using the standard email and
              password registration form
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
export default withRouter(connect(null, { loginApi })(Login));
