import React from "react";
import HalalProduct from "./components/HalalProduct";
import Sitemap from "./components/Sitemap";
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const ProductDetail = React.lazy(() => import("./components/Product"));
const Category = React.lazy(() => import("./components/Category"));
const ManageProduct = React.lazy(() => import("./components/Seller/Store/ManageProduct"));
const VerifyStore = React.lazy(() => import("./components/Seller/Store/VerifyStore"));
const Tender = React.lazy(() => import("./components/Tender"));
const Career = React.lazy(() => import("./components/Career"));
const FooterTender = React.lazy(() => import("./components/FooterTender"));
const ShoppingCart = React.lazy(() =>
  import("./components/Product/ShoppingCart")
);
const Checkout = React.lazy(() =>
  import("./components/Product/ShoppingCart/Checkout")
);
const SearchProduct = React.lazy(() =>
  import("./components/Product/SearchProduct")
);
const Payment = React.lazy(() => import("./components/Payment/Payment"));
const Account = React.lazy(() => import("./components/Account"));
const StorePage = React.lazy(() =>
  import("./components/Seller/Store/StorePage")
);
const Buyer = React.lazy(() => import("./components/Buyer"));
const Order = React.lazy(() => import("./components/Buyer/Order"));
const OrderDetail = React.lazy(() =>
  import("./components/Buyer/Order/OrderDetail")
);
const SalesOrders = React.lazy(() => import("./components/Seller/SalesOrders"));
const SalesOrdersDetail = React.lazy(() =>
  import("./components/Seller/SalesOrders/SalesOrdersDetail")
);
const Wishlist = React.lazy(() => import("./components/Buyer/Wishlist"));
const Blogs = React.lazy(() => import("./components/Blogs"));
const BlogDetails = React.lazy(() => import("./components/Blogs/BlogDetails"));
const RefundForm = React.lazy(() =>
  import("./components/Buyer/Order/RefundForm")
);
const Agency = React.lazy(() => import("./components/Agency"));
const Formulir = React.lazy(() => import("./components/Formulir"));
const About = React.lazy(() => import("./components/About"));
const Faq = React.lazy(() => import("./components/FAQ"));
const Fda = React.lazy(() => import("./components/FDA"));
const Contact = React.lazy(() => import("./components/ContactUs"));
const Term = React.lazy(() => import("./components/TermAndCondition"));
const PartnerProgram = React.lazy(() => import("./components/Partner"));
const HowToBuy = React.lazy(() => import("./components/HowToBuy"));
const HowToSell = React.lazy(() => import("./components/HowToSell"));
const SuccessPage = React.lazy(() => import("./components/Payment/MessagePages/SuccessPage"))
const Notification = React.lazy(() => import("./components/Notification"));
export const routes = [
  {
    path: "/",
    exact: true,
    main: Dashboard
  },
  {
    path: "/product-detail/:permalink",
    exact: true,
    main: ProductDetail
  },
  {
    path: "/category/:permalink",
    exact: true,
    main: Category,
  },
  {
    path: "/category/:permalink/:subCategory",
    exact: true,
    main: Category,
  },
  {
    path: "/store-info/:permalink",
    exact: true,
    main: StorePage
  },
  {
    path: "/manage-product",
    checkAuthentication: true,
    main: ManageProduct
  },
  {
    path: "/store/verify-store",
    checkAuthentication: true,
    main: VerifyStore
  },
  // {
  //   path: "/create-store",
  //   exact: true,
  //   main: StorePage
  // },
  // {
  //   path: "/store",
  //   checkAuthentication: true,
  //   // exact: true,
  //   main: StorePage
  // },
  {
    path: "/shopping-cart",
    exact: true,
    main: ShoppingCart
  },
  {
    path: "/checkout",
    exact: true,
    checkAuthentication: true,
    main: Checkout
  },
  {
    path: "/tenders",
    checkAuthentication: true,
    main: Tender
  },
  {
    path: "/rfqs",
    checkAuthentication: true,
    main: Tender
  },
  {
    path: "/buyer",
    checkAuthentication: true,
    main: Buyer
  },
  {
    path: "/search-product",
    exact: true,
    main: SearchProduct
  },
  {
    path: "/payment",
    exact: true,
    checkAuthentication: true,
    main: Payment
  },
  {
    path: "/account",
    checkAuthentication: true,
    main: Account
  },
  {
    path: "/orders",
    checkAuthentication: true,
    exact: true,
    main: Order
  },
  {
    path: "/orders/order-detail/:id",
    checkAuthentication: true,
    exact: true,
    main: OrderDetail
  },
  {
    path: "/manage-sales-orders",
    checkAuthentication: true,
    exact: true,
    main: SalesOrders
  },
  {
    path: "/manage-sales-orders/order-detail/:id",
    checkAuthentication: true,
    exact: true,
    main: SalesOrdersDetail
  },
  {
    path: "/wishlist",
    checkAuthentication: true,
    exact: true,
    main: Wishlist
  },
  {
    path: "/blogs",
    checkAuthentication: false,
    exact: true,
    main: Blogs
  },
  {
    path: "/blogs/blog-details/:permalink",
    checkAuthentication: false,
    exact: true,
    main: BlogDetails
  },
  {
    path: "/orders/:id/refund-form",
    checkAuthentication: true,
    exact: true,
    main: RefundForm
  },
  {
    path: "/payment/success",
    checkAuthentication: true,
    exact: true,
    main: SuccessPage
  },
  {
    path: "/notification",
    checkAuthentication: true,
    exact: true,
    main: Notification
  },
  {
    path: "/agency",
    main: Agency,
  },
  {
    path: "/formulir",
    main: Formulir,
  },
  {
    path: "/about",
    main: About,
  },
  {
    path: "/term-and-condition",
    main: Term,
  },
  {
    path: "/career",
    main: Career,
  },
  {
    path: "/how-to-buy",
    main: HowToBuy,
  },
  {
    path: "/how-to-sell",
    main: HowToSell,
  },
  {
    path: "/footer-tender",
    main: FooterTender,
  },
  {
    path: "/faq",
    main: Faq,
  },
  {
    path: "/fda-registration",
    main: Fda,
  },
  {
    path: "/partner-program",
    main: PartnerProgram,
  },
  {
    path: "/contact",
    main: Contact,
  },
  {
    path: "/notification/:id",
    checkAuthentication: true,
    exact: true,
    main: Notification
  },
  {
    path: "/halal-product",
    exact: true,
    main: HalalProduct,
  },
  {
    path: "/sitemap",
    exact: true,
    main: Sitemap,
  },
];
export default routes;
