import React, { Component } from "react";
import close from "../../../assets/img/close.svg";
// import Modal from "react-bootstrap4-modal"
import "./Modal.scss";
export default class ReusableModal extends Component {
  componentDidMount() {
    // if (!this.props.modalfrom) {
    document.body.style.overflowY = "hidden";
    document.addEventListener("mousedown", this.handlePopupClick, false);
    // }
  }
  componentWillUnmount() {
    document.body.style.overflowY = "unset";
    // if (!this.props.modalfrom) {
    document.removeEventListener("mousedown", this.handlePopupClick, false);
    // }
  }
  handlePopupClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    !this.props.preventToggleOnClickOutsideModal && this.props.hideModal();
  };
  render() {
    let { id, className, title, children, bodyStyle } = this.props;
    return (
      <div className="modal-wrapper">
        <div
          id={id}
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className={"modal-dialog modal-dialog-centered " + className}>
            <div ref={node => (this.node = node)} className="modal-content">
              {title && (
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">
                    {title}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.props.hideModal}
                  >
                    <img src={close} alt="close modal" />
                  </button>
                </div>
              )}
              {children && <div className={`modal-body ${bodyStyle ? bodyStyle : ""}`} >{children}</div>}
            </div>
          </div>
        </div>
      </div>

      // <Modal visible={true} id={id} dialogClassName={className} ref={node => (this.node = node)} >
      //     {title &&
      //         <div className="modal-header">
      //             <h5 className="modal-title" id="exampleModalCenterTitle">{title}</h5>
      //             <button type="button" className="close" data-dismiss="modal" aria-label="Close"
      //                 onClick={this.props.hideModal}>
      //                 <img src={close} alt="close modal" />
      //             </button>
      //         </div>
      //     }
      //     {children &&
      //         <div className="modal-body">
      //             {children}
      //         </div>}
      // </Modal>
    );
  }
}
