import { LOGIN, USERPROFILE, GET_ADDRESSES } from "../actions/typesOfActions";
const intialState = {
  user: {},
  addresses: [],
  defaultAddress: {}
};
export default function (state = intialState, action) {
  switch (action.type) {
    case LOGIN:
    case USERPROFILE:
      // case USERDATA:
      return {
        ...state,
        user: Object.assign({}, state.user, action.user)
      };
    case GET_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses,
        defaultAddress: (action.addresses || []).find(a => a.is_default ) || {}
      }
    default:
      return state;
  }
}
