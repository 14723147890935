import firebase from 'firebase'
import 'firebase/firestore'

import moment from 'moment'
const db = firebase.firestore();

const conversationModel = {
    store: {
        storeId: '',
        storeName: '',
        avatar: '',
        unreadMessage: 0
    },
    user: {

        uid: '',
        full_name: '',
        avatar: '',
        unreadMessage: 0
    },
    currentProductId: 0,
    lastMessage: {},
    createdAt: Date.now(),
    updatedAt: Date.now()
}

const msgTypeEnum = ['text' , 'image' , 'product' ]

const messageModel = {
    conversationId: '',
    sender: '',
    full_name: '',
    msgType: msgTypeEnum[0],
    message: '',
    avatar: '',
    product:  {
        productId: 0,
        productName: '',
        sell_price: 0,
        currency: '',
        average_rating: 0,
        thumbnail_image: '',
        unit_measure: ''
    },
    createdAt: Date.now()
}

const createConversation = (conversationId, conversation = conversationModel) => {
    return db.collection("conversations").doc(conversationId).set(conversation)
        .then(() => true)
        .catch((error) => {
            console.error("[createConversation] Error writing document: ", error);
            return false
        });
}

const updateConversation = (conversationId, message = messageModel, conversation = conversationModel, sender = 'user') => {
    const updateData = {
        lastMessage: message,
        updatedAt: Date.now(),
        [sender]: {
            ...conversation[sender],
            unreadMessage: (conversation[sender].unreadMessage || 0) + 1
        }
    }

    if(message.product) {
        updateData.currentProductId = message.product.productId
    }

    return db.collection("conversations").doc(conversationId).update(updateData)
        .then(() => true)
        .catch((error) => {
            console.error("[updateConversation] Error writing document: ", error);
            return false
        });
}

export const unreadMessage = (conversationId, sender) => {
    getConversation(conversationId)
        .then(conv => {
            if (conv) {
                db.collection("conversations").doc(conversationId).update({
                    [sender]: {
                        ...conv[sender],
                        unreadMessage: 0
                    }
                })
            }
        })

}

const getConversation = (conversationId) => {
    return db.collection("conversations").doc(conversationId).get()
        .then(doc => doc.data())
        .catch((error) => console.error("[getConversation] Error get document: ", error));
}

export const listenConversation = (conversationId, callback) => {
    return db.collection("conversations").doc(conversationId)
            .onSnapshot((querySnapshot) => {
                const convs = (querySnapshot && querySnapshot.data()) || {};
                callback(convs)
            });
}

const createMessage = (message = messageModel) => {

    return db.collection("messages").doc(String(moment().valueOf())).set(message)
        .then(() => true)
        .catch((error) => {
            console.error("[createConversation] Error writing document: ", error);
            return false
        });
}

export const listenConversationsStore = (storeId, callback) => {
    db.collection("conversations").where("store.storeId", "==", storeId)
        .onSnapshot((querySnapshot) => {
            const convs = [];
            querySnapshot.forEach((doc) => {
                convs.push(doc.data());
            });
            callback(convs)
        });
}

export const listenConversationsUser = (uid, callback) => {
    db.collection("conversations").where("user.uid", "==", uid)
        .onSnapshot((querySnapshot) => {
            const convs = [];
            querySnapshot.forEach((doc) => {
                convs.push(doc.data());
            });
            callback(convs)
        });
}

export const sendMessage = (message = messageModel, conversation = conversationModel, sender = 'user') => {
    getConversation(message.conversationId)
        .then(conv => {
            if (conv) {
                updateConversation(message.conversationId, message, conv, sender)
            } else {
                createConversation(message.conversationId, conversation)
            }
        })

    createMessage(message)

}

export const listenMessages = (conversationId, callback) => {
    db.collection("messages").where("conversationId", "==", conversationId)
        .onSnapshot((querySnapshot) => {
            const messages = [];
            querySnapshot.forEach((doc) => {
                messages.push(doc.data());
            });
            callback(messages)
        });

}


export const listenNotificationUser = (uid, callback) => {
    db.collection("conversations").where("user.uid", "==", uid)
        .onSnapshot((querySnapshot) => {
            let count = 0
            querySnapshot.forEach((doc) => {
                count = count + (doc.data().user.unreadMessage || 0)
            });

            callback(count)
        });

}


export const listenNotificationStore = (storeId, callback) => {
    db.collection("conversations").where("store.storeId", "==", storeId)
        .onSnapshot((querySnapshot) => {
            let count = 0
            querySnapshot.forEach((doc) => {
                count = count + (doc.data().store.unreadMessage || 0)
            });

            callback(count)
        });

}
