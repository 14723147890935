import global from "../../global";
import store from "../configStore";
import { handleUnauthorisedUsers } from "../../actions";
const globalApiUrl = global.config.apiUrl;
const globalApiUrlV2 = global.config.apiUrlV2;
const globalApiUrlV3 = global.config.apiUrlV3;
let basicAuthToken = "Basic " + btoa("Aspenku:Aspenku");
// const handleUnauthorizedUser = () => {
//   cookies.remove("user_Token", { path: "/" });
//   cookies.remove("uid", { path: "/" });
//   cookies.remove("user_Email", { path: "/" });
//   history.push("/");
// };

export default {
  post: (url, data, headerData, version) => {
    let header = {};
    if (headerData === "basicAuth") {
      header = {
        method: "POST",
        headers: {
          authorization: basicAuthToken
        },
        body: JSON.stringify(data)
      };
    } else if (headerData === "formData") {
      header = {
        method: "POST",
        headers: {
          authorization: "Bearer " + global.getAuthToken()
        },
        body: data
      };
    } else {
      header = {
        method: "POST",
        headers: {
          authorization: "Bearer " + global.getAuthToken()
        },
        body: JSON.stringify(data)
      };
    }
    // let apiUrl = globalApiUrl + url;
    let apiUrl = version === "v2" ? globalApiUrlV2 + url : 
                                    (version === 'v3' ) ? globalApiUrlV3 + url : globalApiUrl + url;
    return fetch(apiUrl, header)
      .then(response => {
        return response
          .json()
          .then(json => {
            if (response.status === 401) {
              store.dispatch(handleUnauthorisedUsers());
            }
            return json;
          })
          .catch(err => {
            return Promise.resolve({
              response: response
            });
          });
      })
      .catch(err => {
        console.log("api post error:", err);
        throw err;
      });
  },
  get: (url, basicAuth, version) => {
    // let apiUrl = version === "v2" ? globalApiUrlV2 + url : globalApiUrl + url;
    let apiUrl = version === "v2" ? globalApiUrlV2 + url : 
                                    (version === 'v3' ) ? globalApiUrlV3 + url : globalApiUrl + url;

    console.log(apiUrl)
    return fetch(apiUrl, {
      method: "GET",
      headers: {
        authorization: basicAuth
          ? basicAuthToken
          : "Bearer " + global.getAuthToken()
      }
    })
      .then(response => {
        return response
          .json()
          .then(json => {
            if (response.status === 401) {
              store.dispatch(handleUnauthorisedUsers());
            }
            return json;
          })
          .catch(err => {
            return Promise.resolve({
              response: response
            });
          });
      })
      .catch(err => {
        console.log("api post error:", err);
        throw err;
      });
  },

  put: (url, data, basicAuth, version) => {
    let header = data
      ? {
          method: "PUT",
          headers: {
            authorization: basicAuth
              ? basicAuthToken
              : "Bearer " + global.getAuthToken()
          },
          body: JSON.stringify(data)
        }
      : {
          method: "PUT",
          headers: {
            authorization: basicAuth
              ? basicAuthToken
              : "Bearer " + global.getAuthToken()
          }
        };
    let apiUrl = version === "v2" ? globalApiUrlV2 + url : 
        (version === 'v3' ) ? globalApiUrlV3 + url : globalApiUrl + url;
    // return fetch(globalApiUrl + url, header)
    return fetch(apiUrl, header)
      .then(response => {
        return response
          .json()
          .then(json => {
            if (response.status === 401) {
              store.dispatch(handleUnauthorisedUsers());
            }
            return json;
          })
          .catch(err => {
            return Promise.resolve({
              response: response
            });
          });
      })
      .catch(err => {
        console.log("api post error:", err);
        throw err;
      });
  },
  delete: (url, basicAuth, version) => {
    let apiUrl = version === "v2" ? globalApiUrlV2 + url : globalApiUrl + url;
    // return fetch(globalApiUrl + url, {
    return fetch(apiUrl, {
      method: "DELETE",
      headers: {
        authorization:
          basicAuth === "basicAuth"
            ? basicAuthToken
            : "Bearer " + global.getAuthToken()
      }
    })
      .then(response => {
        return response.json().then(json => {
          if (response.status === 401) {
            store.dispatch(handleUnauthorisedUsers());
          }
          return json;
        });
      })
      .catch(err => {
        console.log("api post error:", err);
        throw err;
      });
  }
  //   patch: (url, data) => {
  //     let header = {
  //       method: "PATCH",
  //       headers: {
  //         authorization: "Bearer " + global.getAuthToken()
  //       },
  //       ...(data && { body: JSON.stringify(data) })
  //     };
  //     return fetch(globalApiUrl + url, header)
  //       .then(response => {
  //         return response.json().then(json => {
  //           if (response.status === 401) {
  //             store.dispatch(handleUnauthorisedUsers());
  //           }
  //           return json;
  //         });
  //       })
  //       .catch(err => {
  //         console.log("api patch error:", err);
  //         throw err;
  //       });
  //   }
};
