import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./HeaderBottomSection.scss";
import global from "../../../../global";
import { getCategories, getStore, changeCurrency } from "../../../../actions";
import { selectMenuStore } from "../../../../actions/store";
import _ from "lodash";
// import $ from "jquery";
// import arrowdown from "../../../../assets/img/arrow-down.svg";
// import humberger from "../../../../assets/img/humberger.svg";
class HeaderBottomSection extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("click", this.handleOpenMenu);
  }

  state = {
    leftMenu: {
      id: "categories",
      displayText: "Categories",
      list: [],
      active: false,
      activeCategoryId: "",
      activeSubCategoryId: ""
    },
    rightMenu: [
      {
        id: "seller",
        displayText: "Seller",
        list: [
          {
            id: "create_store",
            displayText: "Create Store",
            path: "/store/manage-store"
          },
          {
            id: "update_store",
            displayText: "Update Store",
            path: "/store-info/manage-store"
          },
          {
            id: "manage_product",
            displayText: "Manage Product",
            path: "/store-info/manage-store"
          },
          {
            id: "manage_sales_order",
            displayText: "Manage Sales Order",
            path: "/manage-sales-orders"
          },
          {
            id: "manage_bid",
            displayText: "Manage Bid",
            path: { pathname: "/tenders", state: { user_bidding: true } }
          },
          {
            id: "verify_store",
            displayText: "Verify Store",
            path: "/store/verify-store"
          }
        ]
      },
      {
        id: "buyer",
        displayText: "Buyer",
        list: [
          // {
          //   id: "browse_products",
          //   displayText: "Browse Products",
          //   path: ""
          // },
          // {
          //   id: "verify_buyer",
          //   displayText: "Verify Buyer",
          //   path: "/buyer/verify_buyer"
          // },
          {
            id: "my_order",
            displayText: "My Order",
            path: "/orders"
            // path: ""
          },
          {
            id: "manage_address",
            displayText: "Manage Buyer Address",
            path: "/account/manage-address"
            // path: ""
          },
          // {
          // 	id: "payment_confirmation",
          // 	displayText: "Payment Confirmation",
          // 	path: "",
          // },
          // {
          //   id: "my_bidding",
          //   displayText: "My Bidding",
          //   path: { pathname: "/tenders", state: { user_bidding: false } }
          // },
          {
            id: "open_tender",
            displayText: "Open Tender",
            path: { pathname: "/tenders", state: { user_bidding: false } }
          },
          {
            id: "rfq_list",
            displayText: "RFQ List",
            path: "/rfqs"
          },
          {
            id: "wishlist",
            displayText: "Wishlist",
            path: "/wishlist"
            // path: ""
          }
        ]
      },
      {
        id: "currency",
        displayText: "Currency ",
        list: [
          {
            id: "USD",
            displayText: "US Dollar (USD)",
            menuType: "currency"
          },
          {
            id: "IDR",
            displayText: "Indonesian Rupiah (IDR)",
            menuType: "currency"
          },
          {
            id: "MYR",
            displayText: "Malaysian Ringgit (MYR)",
            menuType: "currency"
          },
          {
            id: "QAR",
            displayText: "Qatar Riyal (QAR)",
            menuType: "currency"
          },
          {
            id: "NZD",
            displayText: "New Zealand Dollar (NZD)",
            menuType: "currency"
          },
          {
            id: "SAR",
            displayText: "Saudi Riyal (SAR)",
            menuType: "currency"
          },
          {
            id: "GBP",
            displayText: "Pound Sterling (GBP)",
            menuType: "currency"
          },
          {
            id: "JPY",
            displayText: "Japanese Yen (JPY)",
            menuType: "currency"
          },
          {
            id: "EUR",
            displayText: "European Euro (EUR)",
            menuType: "currency"
          },
        ]
      }
    ],
    active_menu: "",
    storeExists: false,
    active_currency_menu: "USD",
    isLoggedIn: false
  };

  componentWillMount = () => {
    this.getAllCategory();
    if (global.getAuthToken()) {
      this.getStore();
    }
  };
  componentWillReceiveProps = nextProps => {
    if (this.props !== nextProps) {
      if (global.getAuthToken()) {
        !nextProps.store && this.getStore();
      }
      if (nextProps.store && nextProps.store.id) {
        this.setState({
          storeExists: true
        });
      } else {
        this.setState({
          storeExists: false
        });
      }
      if (this.props.subCategory !== nextProps.subCategory) {
        this.setState({
          leftMenu: {
            ...this.state.leftMenu,
            activeSubCategoryId: nextProps.subCategory.subCategoryId
          }
        });
      }
      if (this.state.active_currency_menu !== nextProps.currency) {
        this.setState({
          active_currency_menu: nextProps.currency
        });
      }
      !_.isEmpty(nextProps.user)
        ? this.setState({
          isLoggedIn: true
        })
        : this.setState({
          isLoggedIn: false
        });
    }
  };

  getAllCategory = () => {
    let payload = {
      subCategory: true
    };
    this.props.getCategories(payload).then(response => {
      if (response.statusCode === 200) {
        this.setState({
          leftMenu: { ...this.state.leftMenu, list: response.data.rows }
        });
      }
      // else {
      // 	this.setState({
      // 		isAlert: true,
      // 		errorMessage:
      // 			response.statusCode === 400
      // 				? global.errors.error400
      // 				: response.message,
      // 		alertStyle: "danger"
      // 	});
      // }
    });
  };

  handleMenuClick = active_menu => {
    this.setState({
      active_menu: this.state.active_menu === active_menu ? "" : active_menu
    });
  };

  toggleSubMenu = categoryId => {
    this.setState({
      leftMenu: {
        ...this.state.leftMenu,
        activeCategoryId:
          this.state.leftMenu.activeCategoryId === categoryId ? "" : categoryId
      }
    });
  };

  getStore = () => {
    if (!this.state.storeExists) {
      this.props.getStore().then(res => {
        if (res.statusCode === 200) {
          if (res.data && res.data.id) {
            this.setState({
              storeExists: true
            });
          } else {
            this.setState({
              storeExists: false
            });
          }
        }
      });
    }
  };

  handleOpenMenu = e => {
    if (this.rightMenu.contains(e.target) || this.leftMenu.contains(e.target)) {
      return;
    }
    this.setState({
      active_menu: ""
    });
  };

  handleSubcategoryClick = (categoryId, subCategoryId, subCategoryName, permalink) => {
    this.setState(
      {
        leftMenu: { ...this.state.leftMenu, activeSubCategoryId: subCategoryId }
      },
      () => {
        this.dismissMenu();
        this.props.history.push({
          pathname: "/category/" + permalink,
          state: {
            categoryId,
            subCategoryId: subCategoryId,
            subCategoryName: subCategoryName
          }
        });
      }
    );
  };

  handleCategoryClick = (categoryId, permalink) => {
    this.setState(
      {
        leftMenu: { ...this.state.leftMenu, activeCategoryId: categoryId }
      },
      () => {
        this.dismissMenu();
        this.props.history.push({
          pathname: "/category/" + permalink,
          state: {
            categoryId
          }
        });
      }
    );
  };

  dismissMenu = menuInfo => {
    if (menuInfo) {
      if (menuInfo.menuType === "currency") {
        this.setState({
          active_currency_menu: menuInfo.id
        });
        this.props.changeCurrency(menuInfo.id);
      } else if (menuInfo.path) {
        if(menuInfo.id === 'create_store' || menuInfo.id === 'update_store' ) { 
          this.props.selectMenuStore('manage_store')
          menuInfo.id === 'create_store' && this.props.history.push('/store-info/create-store')
          menuInfo.id === 'update_store' && this.props.history.push('/store-info/'+ encodeURI((this.props.store.permalink || 'new-store')) )
        }
        else if(menuInfo.id === 'manage_product'){
          this.props.selectMenuStore('main_profile')
          this.props.history.push('/store-info/'+ encodeURI(this.props.store.permalink) , { id: menuInfo.id  } )
        }else{
          this.props.history.push(menuInfo.path);
        }
      }
    }
    this.setState({
      active_menu: ""
    });
  };

  changeActiveSubCategory = subCategoryId => {
    this.setState({
      leftMenu: { ...this.state.leftMenu, activeSubCategoryId: subCategoryId }
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.handleOpenMenu);
  };

  render() {
    let {
      leftMenu,
      rightMenu,
      active_menu,
      storeExists,
      active_currency_menu,
      isLoggedIn
    } = this.state;
    let activeCategory = leftMenu.list.filter(
      category => leftMenu.activeCategoryId === category.id
    )[0];

    return (
      <>
        <div className="bottom">
          <div className="container h-100">
            <div className="level h-100">
              {/* Left menu */}
              <div className="left h-100">
                <div
                  tabIndex={1}
                  className={
                    "header-menu-item left_menu " +
                    (active_menu === leftMenu.id ? "active-menu-item" : "")
                  }
                  ref={node => (this.leftMenu = node)}
                  // onClick={() => this.handleMenuClick(leftMenu.id)}
                  // onMouseEnter={() => this.handleMenuClick(leftMenu.id)}
                  onBlur={() => { 
                    this.handleMenuClick(leftMenu.id)
                    this.toggleSubMenu()
                  }}
                >
                  <span
                    className="level-item categories cursor-pointer"
                    onClick={() => this.handleMenuClick(leftMenu.id)}
                  // onMouseEnter={() => this.handleMenuClick(leftMenu.id)}
                  // onClick={() => this.handleMenuClick(leftMenu.id)}
                  >
                    <i className="fa fa-bars mx-3" />
                    <span>{leftMenu.displayText}</span>
                    <i
                      className={
                        "fa mx-3 " +
                        (active_menu === leftMenu.id
                          ? "fa-angle-up"
                          : "fa-angle-down")
                      }
                    ></i>
                  </span>
                  {/* categories menu */}
                  {active_menu === leftMenu.id && (
                    // <div className={"menu main-menu " + (active_menu === leftMenu.id ? "d-block" : "")}>
                    <div
                      className="menu main-menu"
                    // onMouseLeave={() => this.handleMenuClick(leftMenu.id)}
                    >
                      <ul>
                        {_.sortBy(leftMenu.list, category => {
                          return _.capitalize(category.category_name);
                        }).map((category, index) => {
                          let SpreeSubCategories = category.SpreeSubCategories;
                          return (
                            <React.Fragment
                              key={category.category_name + "_" + index}
                            >
                              {SpreeSubCategories.length > 0 ? (
                                <li
                                  className={
                                    "menu-item " +
                                    (leftMenu.activeCategoryId === category.id
                                      ? "active"
                                      : "")
                                  }
                                  id={category.id + "_active_category"}
                                  onClick={() =>
                                    this.toggleSubMenu(category.id)
                                  }
                                  // onMouseEnter={() =>
                                  //   this.toggleSubMenu(category.id)
                                  // }
                                >
                                  <div className="text">
                                    <span className="cursor-pointer">
                                      {_.capitalize(category.category_name)}
                                    </span>
                                    <i
                                      className={
                                        "fa " +
                                        (leftMenu.activeCategoryId ===
                                          category.id
                                          ? "fa-angle-left"
                                          : "fa-angle-right")
                                      }
                                    />
                                  </div>
                                  {activeCategory &&
                                    activeCategory.id === category.id &&
                                    activeCategory.SpreeSubCategories.length >
                                    0 ? (
                                      // <ul className={"menu sub-menu " + (leftMenu.activeCategoryId ? "d-block" : "")}>
                                      <ul
                                        className="menu sub-menu"
                                        style={
                                          {
                                            // top: $("#" + leftMenu.activeCategoryId + "_active_category").length ? $("#" + leftMenu.activeCategoryId + "_active_category").offset().top : 0
                                          }
                                        }
                                      >
                                        <li className="title">
                                          <span>
                                            {_.capitalize(
                                              activeCategory.category_name
                                            )}
                                          </span>
                                        </li>
                                        {_.sortBy(
                                          activeCategory.SpreeSubCategories,
                                          subCategory => {
                                            return _.capitalize(
                                              subCategory.sub_category_name
                                            );
                                          }
                                        ).map((subCategory, index) => (
                                          <li
                                            className={
                                              "menu-item " +
                                              (leftMenu.activeSubCategoryId ===
                                                subCategory.id
                                                ? "active"
                                                : "")
                                            }
                                            key={index}
                                            onClick={() =>
                                              this.handleSubcategoryClick(
                                                activeCategory.id,
                                                subCategory.id,
                                                subCategory.sub_category_name,
                                                activeCategory.permalink
                                              )
                                            }
                                          >
                                            {_.capitalize(
                                              subCategory.sub_category_name
                                            )}
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                </li>
                              ) : (
                                  // Category without subcategory
                                  <li
                                    className={
                                      "menu-item " +
                                      (leftMenu.activeCategoryId === category.id
                                        ? "active"
                                        : "")
                                    }
                                    onMouseEnter={() => {
                                      this.setState({
                                        leftMenu: {
                                          ...this.state.leftMenu,
                                          activeCategoryId: ""
                                        }
                                      });
                                    }}
                                    onClick={() =>
                                      this.handleCategoryClick(category.id)
                                    }
                                  >
                                    <span>
                                      {_.capitalize(category.category_name)}
                                    </span>
                                  </li>
                                )}
                            </React.Fragment>
                          );
                        })}
                      </ul>
                      {/* Subcategories menu */}
                      {/* {activeCategory &&
                        activeCategory.SpreeSubCategories.length > 0 ? (
                          // <ul className={"menu sub-menu " + (leftMenu.activeCategoryId ? "d-block" : "")}>
                          <ul className="menu sub-menu" style={{
                            // top: $("#" + leftMenu.activeCategoryId + "_active_category").length ? $("#" + leftMenu.activeCategoryId + "_active_category").offset().top : 0
                          }}>
                            <li className="title">
                              <span>{_.capitalize(activeCategory.category_name)}</span>
                            </li>
                            {
                              _.sortBy(activeCategory.SpreeSubCategories, subCategory => {
                                return _.capitalize(subCategory.sub_category_name);
                              }).map(
                                (subCategory, index) => (
                                  <li
                                    className={
                                      "menu-item " +
                                      (leftMenu.activeSubCategoryId ===
                                        subCategory.id
                                        ? "active"
                                        : "")
                                    }
                                    key={index}
                                    onClick={() =>
                                      this.handleSubcategoryClick(
                                        activeCategory.id,
                                        subCategory.id,
                                        subCategory.sub_category_name
                                      )
                                    }
                                  >
                                    {_.capitalize(subCategory.sub_category_name)}
                                  </li>
                                )
                              )}
                          </ul>
                        ) : (
                          ""
                        )} */}
                    </div>
                  )}
                </div>
              </div>
              {/* Right side menu */}
              <div
                className="right h-100 w-50"
                ref={node => (this.rightMenu = node)}
              >
                {rightMenu.map((menu, index) => (
                  <React.Fragment key={index}>
                    {((menu.id !== "currency" && isLoggedIn) ||
                      menu.id === "currency") && (
                        <div
                          className={
                            "header-menu-item right_menu " +
                            (active_menu === menu.id ? "active-menu-item" : "")
                          }
                          key={menu.id + "_" + index}
                          // onClick={() => this.handleMenuClick(menu.id)}
                          onMouseEnter={() => this.handleMenuClick(menu.id)}
                          onMouseLeave={() => this.handleMenuClick(menu.id)}
                        >
                          <span
                            className="level-item cursor-pointer"
                          // onMouseEnter={() => this.handleMenuClick(menu.id)}
                          // onClick={() => this.handleMenuClick(menu.id)}
                          >
                            <span className="ml-3">
                              {menu.id === "currency"
                                ? menu.displayText +
                                " (" +
                                active_currency_menu +
                                ")"
                                : menu.displayText}
                            </span>
                            <i
                              className={
                                "fa mx-3 " +
                                (active_menu === menu.id
                                  ? "fa-angle-up"
                                  : "fa-angle-down")
                              }
                            ></i>
                          </span>
                          {active_menu === menu.id ? (
                            <div
                              className="menu main-menu"
                            // onMouseLeave={() => this.handleMenuClick(menu.id)}
                            >
                              {/* <div className={"menu main-menu " + (active_menu === menu.id ? "d-block" : "")}> */}
                              <ul>
                                {menu.list.map((menuItem, index) => {
                                  return (
                                    <React.Fragment
                                      key={menuItem.id + "_" + index}
                                    >
                                      {menu.id === "seller" ? (
                                        ((!storeExists &&
                                          menuItem.id === "create_store") ||
                                          (storeExists &&
                                            menuItem.id !== "create_store")) && (
                                          <li
                                            className={
                                              "menu-item " +
                                              (this.props.location.pathname ===
                                                menuItem.path
                                                ? "active"
                                                : "")
                                            }
                                            onClick={() =>
                                              this.dismissMenu(menuItem)
                                            }
                                          >
                                            {/* <NavLink to={menuItem.path}> */}
                                            {menuItem.displayText}
                                            {/* </NavLink> */}
                                          </li>
                                        )
                                      ) : (
                                          // Currency list item
                                          <li
                                            className={
                                              "menu-item " +
                                              (active_currency_menu === menuItem.id
                                                ? " active"
                                                : "")
                                            }
                                            onClick={() =>
                                              this.dismissMenu(menuItem)
                                            }
                                          >
                                            {/* <NavLink to={menuItem.path}> */}
                                            {menuItem.displayText}
                                            {/* </NavLink> */}
                                          </li>
                                        )}
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                              ""
                            )}
                        </div>
                      )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  categoriesList: state.categories.categoriesList,
  user: state.login.user,
  store: state.store.storeInfo,
  subCategory: state.subCategory.activeSubCategory,
  currencyRate: state.exchangeRate.rateInfo,
  rate: state.exchangeRate.rate,
  currency: state.exchangeRate.currency
});
export default withRouter(
  connect(mapStateToProps, { getCategories, getStore, changeCurrency , selectMenuStore })(
    HeaderBottomSection
  )
);
