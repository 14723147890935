import { ADDTOCART } from "./typesOfActions";
export const addToCart = () => (dispatch, getState, Api) => {
  dispatch({
    type: ADDTOCART,
    data: JSON.parse(localStorage.getItem("products"))
  });
};
export const shippingCosts = payload => (dispatch, getState, Api) => {
  return Api.post("shippingCosts", payload).then(response => {
    return response;
  });
};
