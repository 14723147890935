import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import './Header.scss';
import HomePageHeaderContent from "./HomePageHeaderContent";
import HeaderMiddleSection from "./HeaderMiddleSection";
import HeaderBottomSection from "./HeaderBottomSection";
import $ from 'jquery';
import _ from "lodash";

class Header extends Component {
  state = {
    animateHeader: false
  }
  constructor(props) {
    super(props);
    window.addEventListener("scroll", () => {
      // $(window).scrollTop() > 100 ?
      $(window).scrollTop() > 380 ?
        this.setState({
          animateHeader: true
        })
        :
        this.setState({
          animateHeader: false
        })
    })
  }
  componentDidMount = () => {
    $("#header #header-home-page-section").css('margin-top', $("#header .header").height());
  }
  handlePage = (id) => {
    let { pages } = this.props
    !_.isEmpty(pages) && pages.map(page => {
      if (page.SpreeSinglePagesCategory.category_name === id) {
        this.handlePath(page.permalink);
      }
      return true;
    })

  }
  handlePath = (pathName) => {
    this.props.history.push("/" + pathName)
  }
  render() {
    let { className, children } = this.props;
    let { animateHeader } = this.state;
    return (
      <React.Fragment>
        <header id="header" className={className}>
          <div className="header">
            <div className={"top " + (animateHeader ? "hide-header-top " : "")}>
              <div className="container">
                <ul>
                  <li><NavLink to="about">About Aspenku</NavLink></li>
                  {/* <li>Helpdesk</li> */}
                  {/* <li>Blog</li> */}
                  <li><NavLink to="/blogs">Blog</NavLink></li>
                </ul>
              </div>
            </div>
            <HeaderMiddleSection history={this.props.history} />
            <HeaderBottomSection />
          </div>
          <div id="header-home-page-section">
            {children && children}
            {this.props.location.pathname === "/" && <HomePageHeaderContent />}
          </div>
        </header>
      </React.Fragment >
    );
  }
}
const mapStateToProps = (state) => ({
  pages: state.pages.pages,

})
export default withRouter(connect(mapStateToProps, {})(Header));
