import {
  EXCHANGERATE, CHANGECURRENCY, SETCURRENCYFROMLOCALSTORAGE,
  //  SETCURRENCY
} from "../actions/typesOfActions";
const intialState = {
  rateInfo: [],
  currencySymbol: "$ ",
  currency: "USD",
  rate: 1
};
export default function (state = intialState, action) {
  switch (action.type) {
    case EXCHANGERATE:
      return {
        ...state,
        rateInfo: action.data
      };
    case CHANGECURRENCY:
      let rate = state.rateInfo.find((rateInfo) => rateInfo.target_currency === action.currencyType) || { symbol_currency: '$ ', target_currency: 'USD', rate: 1 };
      let currencySymbol = rate.symbol_currency
      let currencyInfo = {
        target_currency: rate.target_currency, 
        rate: rate.rate,
        currencySymbol
      }
      localStorage.setItem("currency", JSON.stringify(currencyInfo))
      return {
        ...state,
        currencySymbol,
        currency: rate.target_currency,
        rate: Number(rate.rate)
      };
    case SETCURRENCYFROMLOCALSTORAGE:
      let currency = JSON.parse(localStorage.getItem("currency"));
      return {
        ...state,
        currencySymbol: currency ? currency.currencySymbol : "$ ",
        currency: currency ? currency.target_currency : "USD",
        rate: currency ? Number(currency.rate) : 1
      };
    default:
      return state;
  }
}


