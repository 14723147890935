export const REGISTER = "REGISTER";
export const USERPROFILE = "USERPROFILE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
// export const USERDATA = "USERDATA";
export const CATEGORIES = "CATEGORIES";
export const STORE = "STORE";
export const ACTIVESUBCATEGORY = "ACTIVESUBCATEGORY";
export const ADDTOCART = "ADDTOCART";
export const PRODUCTSEARCHTEXT = "PRODUCTSEARCHTEXT";
export const EXCHANGERATE = "EXCHANGERATE";
export const CHANGECURRENCY = "CHANGECURRENCY";
export const SETCURRENCYFROMLOCALSTORAGE = "SETCURRENCYFROMLOCALSTORAGE";
export const WISHLIST = "WISHLIST";
export const ADDPRODUCTINWISHLIST = "ADDPRODUCTINWISHLIST";
export const REMOVEPRODUCTFROMWISHLIST = "REMOVEPRODUCTFROMWISHLIST";
export const PAGES = "PAGES";
// export const SETCURRENCY = "SETCURRENCY";
export const SELECTED_MENU_STORE='SELECTED_MENU_STORE'
export const SET_USER_STORE='SET_USER_STORE'
export const GET_ADDRESSES='GET_ADDRESSES'