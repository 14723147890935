import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import global from "../../../../global";
import { getBanners } from "../../../../actions";
// import group548 from "../../../../assets/img/Group 548@2x.png";
import bannerslider from "../../../../assets/img/banner-slider.jpg";
import rfqtenderBg from "../../../../assets/img/rfqtender-bg.jpg";
import no_banner_image from "../../../../assets/img/no_banner_image.jpg";
import Login from "../../../Login";
class HomePageHeaderContent extends Component {
  state = {
    banners: [
      {
        image_file_name: bannerslider,
        banner_name: "banner1",
        url: "banner_url_3",
        is_publish: true
      }
    ],
    showLoginModal: false
  };
  componentDidMount = () => {
    this.getBanners();
  };

  getBanners = () => {
    this.props.getBanners().then(res => {
      if (res.statusCode === 200) {
        let banners = res.data;
        // let publishedBanners = banners.filter((banner) => banner.is_publish);
        this.setState({
          banners
        });
      }
    });
  };

  handleRfqTenderRedirection = redirectionUrl => {
    if (!global.getAuthToken()) {
      this.handleLoginModal();
    } else {
      this.props.history.push(redirectionUrl);
    }
  };
  handleLoginModal = () => {
    this.setState({ showLoginModal: !this.state.showLoginModal });
  };
  render() {
    let { banners } = this.state;
    return (
      <>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-md-8 main-banner pr0">
                {banners.length > 0 && (
                  <div
                    id="slider01"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      {banners.map((banner, index) => (
                        <li
                          data-target="#slider01"
                          data-slide-to={index}
                          className={index === 0 ? "active" : ""}
                          key={index}
                        ></li>
                      ))}
                      {/* <li
										data-target="#slider01"
										data-slide-to="0"
										className="active"
									>
										<li data-target="#slider01" data-slide-to="1"></li>
										<li data-target="#slider01" data-slide-to="2"></li> */}
                    </ol>
                    <div className="carousel-inner">
                      {banners.map((banner, index) => (
                        <div
                          className={`carousel-item ${
                            index === 0 ? " active" : ""
                            }`}
                          key={index}
                        >
                          {banner.url ? (
                            <a
                              href={banner.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  global.config.imageUrl +
                                  banner.image_file_name
                                }
                                className="d-block w-100 img-fluid"
                                alt={banner.alt}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = no_banner_image;
                                }}
                              />
                            </a>
                          ) : (
                              <img
                                src={
                                  global.config.imageUrl + banner.image_file_name
                                }
                                className="d-block w-100 img-fluid"
                                alt={banner.alt}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = no_banner_image;
                                }}
                              />
                            )}
                        </div>
                      ))}
                      {/* <div className="carousel-item">
										<img
											src={bannerslider}
											className="d-block w-100 img-fluid"
											alt="banner slider"
										/>
									</div>
									<div className="carousel-item">
										<img
											src={bannerslider}
											className="d-block w-100 img-fluid"
											alt="banner slider"
										/>
									</div> */}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#slider01"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#slider01"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                    {/* <NavLink
                  className="carousel-control-prev"
                  to="#slider01"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </NavLink>
                <NavLink
                  className="carousel-control-next"
                  to="#slider01"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </NavLink> */}
                  </div>
                )}
              </div>
              <div className="col-md-4 side-banner">
                {/* <NavLink to="/tenders"> */}
                <img
                  src={rfqtenderBg}
                  className="img-fluid"
                  alt="side banner"
                />
                <div className="side-banner-content-wrapper">
                  <div className="inner-block">
                    <span className="desc-text">
                      Need high quality products with best prices from the reliable suppliers? Find it easily on Aspenku.com
                    </span>
                    <button
                      // to="/rfqs/create-rfq"
                      //   disabled={!global.getAuthToken() ? "disbaled" : ""}
                      className="rfq-tender-btn btn btn-blue"
                      onClick={() =>
                        this.handleRfqTenderRedirection("/rfqs/create-rfq")
                      }
                    >
                      REQUEST FOR QUOTATION
                    </button>
                    {/* <button
                      // to="/tenders"
                      //   disabled={!global.getAuthToken() ? "disbaled" : ""}
                      className="rfq-tender-btn btn btn-orange"
                      onClick={() =>
                        this.handleRfqTenderRedirection("/tenders")
                      }
                    >
                      OPEN TENDER
                    </button> */}
                  </div>
                </div>
                {/* </NavLink> */}
                {/* <NavLink to="/tenders">
								<img src={group548} className="img-fluid" alt="side banner" />
							</NavLink> */}
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoginModal && (
          <Login hideModal={this.handleLoginModal}></Login>
        )}
      </>
    );
  }
}
// To enable "Request for quotation" and "Create tender" button when user login
const mapStateToProps = state => ({
  user: state.login.user
});
export default withRouter(
  connect(mapStateToProps, { getBanners })(HomePageHeaderContent)
);
