import React, { Component, Suspense } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import routes from "../../Routes";
import Loader from "../reusable/Loader";
import Header from "../wrapperComponents/Header";
import Footer from "../wrapperComponents/Footer";
import global from "../../global";
import _ from "lodash";
import {
  getExchangeRates,
  setCurrencyFromLocalstorage,
  getWishlistProducts,
  handleFooterPages,
  getCurrentLocationOfUser,
  changeCurrency,
  getUserAddresses
} from "../../actions";
import Login from "../Login";
import Cookies from "universal-cookie";
import Page from "../FooterPages/SupportOrCompanyInformationPage"
// import Error404 from "../reusable/Error404";
import SweetAlertBox from "../reusable/SweetAlertBox/SweetAlertBox";
const cookies = new Cookies();
class Homepage extends Component {
  state = {
    isLoading: true,
    routes: routes,
    isError404: false,
    invalidRoute: false,
    lowNetwork: false,
    showLocationPopup: false
  };
  componentWillMount = async () => {
    await this.props.getExchangeRates().then(response => {
      if (response.statusCode === 200) {
        if (!JSON.parse(localStorage.getItem("country"))) {
          this.setState({ showLocationPopup: true })
        }
      }
    }).catch(error => {
      this.setState({ lowNetwork: true })
    });
  }
  componentDidMount = () => {
    // console.log(this.props.location.pathname)
    window.scrollTo(0, 0);
    // this.props.getCurrentLocationOfUser();
    this.props.setCurrencyFromLocalstorage();
    this.handleFooterPages(this.props.location.pathname);
    if (global.getAuthToken()) {
      this.props.getWishlistProducts();
      this.props.getUserAddresses()
      // this.getCountOfUnreadMessage();
    }
    if (_.includes(this.props.location.pathname, "/login/")) {
      let token = _.split(this.props.location.pathname, "/")[2];
      cookies.set("user_Token", token, { path: "/" });
      this.props.history.push("/");
    } 

  };
  componentDidUpdate(prevProps){
    if(prevProps.defaultAddress !== this.props.defaultAddress || prevProps.rateInfo !== this.props.rateInfo || prevProps.profile !== this.props.profile ) {
      if(!_.isEmpty(this.props.defaultAddress) && !_.isEmpty(this.props.rateInfo) && !_.isEmpty(this.props.profile) ){
        const currency_code = _.get(this.props.defaultAddress, 'SpreeCountry.currency_code')
        if(this.props.rateInfo.find(cur => cur.target_currency === currency_code)){
          this.props.changeCurrency(currency_code)
        }
      }
    }

    if(prevProps.profile !== this.props.profile){
      if(!_.isEmpty(this.props.profile)){
        this.props.getWishlistProducts();
        this.props.getUserAddresses()
      }
    }

  }
  componentWillReceiveProps(nextProps) {
    // console.log(routes);
    if (this.props.location.pathname !== nextProps.location.pathname) {
      window.scrollTo(0, 0);
      if (global.getAuthToken()) {
        _.isEmpty(nextProps.wishlistProduct) &&
          this.props.getWishlistProducts();
        // this.getCountOfUnreadMessage();
      }
      this.showLoader();
    }
  }
  checkRoute = (pathName) => {
    // console.log("checkRoute");
    let { routes } = this.state;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path !== pathName) {
        // console.log(pathName);
        this.setState({ invalidRoute: true, isError404: true })
        break;
      }
    }
  }
  showLoader = () => {
    this.setState({
      isLoading: true
    });
  };
  hideLoader = () => {
    if (this.state.isLoading !== false) {
      this.setState({
        isLoading: false
      });
    }
  };
  handleFooterPages = (pathName) => {
    // console.log(pathName);
    this.props.handleFooterPages().then(response => {
      if (!_.isEmpty(response.data)) {
        response.data.map(page => {
          routes.push({
            path: "/" + encodeURI(page.permalink + ''),
            exact: true,
            main: Page,
            data: page
          })
          return true;
        })
        this.setState({
          routes
        }, () => {
          // console.log(this.state.routes);
          this.checkRoute(pathName)
        })

      }
    })
  }
  // getCountOfUnreadMessage = () => {
  //   // let { profile } = this.props;
  //   // this.props.getCountOfUnreadMessage(profile.sendbird_id)
  // }
  handlePageRefresh = () => {
    window.location.reload();
  }
  handleLocation = () => {
    this.setState({
      showLocationPopup: false
    }, () => {
      if (JSON.parse(localStorage.getItem("country"))) {
        this.props.changeCurrency("IDR")
      }
      else {
        localStorage.setItem("country", JSON.stringify("ID"))
        this.props.changeCurrency("IDR")
      }
    })

  }
  handleCancel = () => {
    this.setState({
      showLocationPopup: false
    }, () => {
      localStorage.setItem("country", JSON.stringify("international"))
    })
  }
  render() {
    let LoaderContext = global.loaderContext;
    let { routes, invalidRoute, isError404 } = this.state;
    return (
      <React.Fragment>
        {this.state.isLoading && <Loader />}
        <Header
          className={this.props.location.pathname === "/" ? "homepage" : ""}
        />
        <div className="page-content position-relative">
          <LoaderContext.Provider value={{ hideLoader: this.hideLoader , showLoader: this.showLoader }}>
            <Suspense fallback={<Loader />}>
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    strict
                    render={() => {
                      return (
                        <React.Fragment>
                          {route.checkAuthentication &&
                            !global.getAuthToken() ? (
                              <>
                                {/* <Login {...this.props} /> */}

                                {/* <Redirect to="/" /> */}
                                {/* //----Add for open login modal-----// */}
                                <Login hideModal={this.props.hideModal} />
                              </>
                            ) : (
                              <route.main {...this.props} data={route.data} />
                            )}
                        </React.Fragment>
                      );
                    }}
                  />
                ))}
                {invalidRoute && isError404 && <Route
                  // component={Error404}
                  render={() => <Redirect to="/error404" />}
                />}
                {/* <Route
                  // path={"/error404"}
                  component={Error404}
                // render={() => <Error404 message="Page not found!" />}
                /> */}
              </Switch>
            </Suspense>
          </LoaderContext.Provider>
        </div>
        <Footer />
        {this.state.lowNetwork &&
          <SweetAlertBox
            title="Low Network"
            message="Please check your network !"
            onConfirm={this.handlePageRefresh}
            confirmBtnText={"Reload "}
          />}
        { /*
          (this.state.showLocationPopup) &&
          < SweetAlertBox
            title="Location"
            message="Are you accesing this from Indonesia?"
            onConfirm={this.handleLocation}
            onCancel={this.handleCancel}
            showCancel={true}
          /> 
          */
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  wishlistProduct: state.product.wishlistProducts,
  profile: state.login.user,
  defaultAddress: state.login.defaultAddress,
  rateInfo: state.exchangeRate.rateInfo
});
export default withRouter(
  connect(mapStateToProps, {
    getExchangeRates,
    setCurrencyFromLocalstorage,
    getWishlistProducts,
    handleFooterPages,
    getCurrentLocationOfUser,
    // getCountOfUnreadMessage,
    changeCurrency,
    getUserAddresses
  })(Homepage)
);
