import React, { Component } from "react";
import defaultProductImage from "../../assets/img/defaultNoImageWhite.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import global from "../../global";
import PaginationProductCard from "../reusable/PaginationProductCard";
import {
  getHalalPageBanner,
  getStoreListHalal,
  getProductsHalal,
  RemoveFromWishlist,
} from "../../actions";
import Login from "../Login";
import _ from "lodash"
import Select from "../reusable/FormControls/Select";
import "./HalalProduct.scss"

const sortByOptions = [
  {
    label: "Price: Lowest First",
    value: "lowestFirst",
  },
  {
    label: "Price: Highest First",
    value: "highestFirst",
  },
  {
    label: "Best Rating",
    value: "bestRating",
  },
];

// function BannerNextArrow(props) {
//   const { onClick } = props;
//   return (
//     <div
//       className='slick-right'
//       onClick={onClick}
//     >
//       <span className='fa fa-angle-right' ></span>
//     </div>
//   );
// }

// function BannerPrevArrow(props) {
//   const { onClick } = props;
//   return (
//     <div
//       className='slick-left'
//       onClick={onClick}
//     >
//       <span className='fa fa-angle-left' ></span>
//     </div>
//   );
// }

// const settingSliderBanner = {
//   fade: true,
//   infinite: true,
//   swipeToSlide: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   initialSlide: 0,
//   nextArrow: <BannerNextArrow />,
//   prevArrow: <BannerPrevArrow />
// };

class Category extends Component {
  static contextType = global.loaderContext;
  state = {
    storesList: [],
    banner: {},
    products: [],
    message: null,
    limit: 12,
    skip: 0,
    totalRow: "",
    showModal: false,
    sortBy: {},
    skipStoreList: 0,
    totalStore: 0,
  };
  componentDidMount = () => {
    this.props.getHalalPageBanner().then((response) => {
      this.context.hideLoader();
      if (response.statusCode === 200) {
        global.setTitleOfPage('Halal Product - Aspenku');
        this.setState({ banner: response.data });
        this.getStoresList()
        this.getAllProducts();
       }
    });

  };

  componentDidUpdate(prevProps) {
    // const permalinkNow = _.get(this.props.match, 'params.permalink')
    // const subCategoryId = _.get(this.props.history.location, 'state.subCategoryId')
    // const permalinkPrev = _.get(prevProps.match, 'params.permalink')

    // if(permalinkNow !== permalinkPrev || subCategoryId !== this.state.subCategory_id) {
    //   this.componentDidMount()
    // }

  }

  getStoresList = (skip = 0 ) => {
    this.props.getStoreListHalal({ skip }).then((response) => {
      if (response.statusCode === 200) {
          this.setState({ 
            storesList: response.data.rows, 
            skipStoreList: skip,
            totalStore: response.data.count 
          });
      }
    });
  }


  getAllProducts = () => {
    this.setState({
      message: null,
    });
    let payload = {
      limit: this.state.limit,
      skip: this.state.skip,
      sortBy: this.state.sortBy.value
    };

    this.props.getProductsHalal(payload).then((response) => {
      this.context.hideLoader();
      if (response.statusCode === 200) {
        const product = _.get(response, 'data.product', {})
        if (product.rows.length > 0) {
          this.setState({
            products: product.rows,
            totalRow: product.count,
          });
        } else {
          this.setState({ message: "No products available." });
        }
      }
    });
  };

  changeLimitAndSkip = (skip, data) => {
    this.setState({ skip: skip }, () => {
      this.getAllProducts();
    });
  };

  handleRemoveFromWishlist = (productId) => {
    this.props.RemoveFromWishlist(productId)
  };

  // renderSideBanner = () => {
  //   const sideBanners = this.state.category.SpreeCategorySidebanners || []

  //   return (<div className='col-12 col-md-4 d-flex flex-md-column pl-md-0'>
  //     {sideBanners[0] ? <div className="col-6 col-md-auto d-flex align-items-center justify-content-center" style={{ flex: 1, minHeight: '100px' }} >
  //       <img src={global.config.imageUrl + sideBanners[0].main_image}
  //         className="d-block w-100 img-fluid border-rounded" alt={sideBanners[0].alternative_text} style={{ height: "100%" }} />
  //     </div> : ''}
  //     {sideBanners[1] ?
  //       <div className=' mt-md-2 col-6 col-md-auto d-flex align-items-center justify-content-center' style={{ flex: 1 , minHeight: '100px' }} >
  //         <img src={global.config.imageUrl + sideBanners[1].main_image}
  //           className="d-block w-100 img-fluid border-rounded"
  //           alt={sideBanners[1].alternative_text} style={{ height: "90%" }} />
  //       </div> : ''}
  //   </div>)
  // }

  renderHeaderSlider = () => {
    const banner = this.state.banner
    if (_.isEmpty(banner)) return '';

    return (<div className='col-md-12 pr-sm-0'>
        <img src={global.config.imageUrl + banner.banner_name} style={{ maxHeight: "310px" }}
          className="d-block w-100 img-fluid border-rounded"
          alt={banner.alternative_text}  />
    </div>)
  }

  sortChange = (e) => {
    this.setState(
      {
        sortBy: e,
        skip: 0,
      },
      () => {
        this.getAllProducts()
      }
    );
  };

  onSwipeStores = (val) => {
    const { skipStoreList } = this.state
    const skip = skipStoreList + val < 0 ? 0 : skipStoreList + val
    this.getStoresList(skip )
  }

  renderStoreDesktop = () => {
    const { storesList } = this.state
    return( <div className='container position-relative storelist' >
        <div className='row px-2 mt-2' >            
        {storesList.slice(0,3).map((store, key) => (
          <div  className='col-md-4 col-sm-4 cols  d-flex flex-column px-0' key={key} >
            <a className='d-flex ml-1 ' href={`/store-info/`+ store.permalink } style={{ width: "99%" }}  >
            <img 
              width={350} height={156}
              className='mx-auto cursor-pointer border-rounded'
              src={'/images/store-halal/350/' + _.get(store, 'SpreeCountry.country_code') + '.jpg'}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultProductImage;
              }}
              alt={store.store_name || 'store aspenku'} />
              </a>
              <div className='d-flex align-items-center justify-content-between my-2 mx-auto' style={{ width: '350px' }} >
                <div><span style={{paddingRight: '80px'}}>{_.get(store, 'SpreeCountry.country_name')}</span></div>
                <img className='shadow' width='30' alt='flag' src={`/images/flags/${_.get(store, 'SpreeCountry.country_code')}.svg`} title={_.get(store, 'SpreeCountry.country_name')} />
              </div>
          </div>
        ))}
        </div>
        <div className='row mt-md-1 px-2' >
        {storesList.slice( 3,6).map((store, key) => (
          <div  className='col-md-4  col-sm-4 cols  d-flex flex-column px-0' key={key} >
            <a  className='d-flex ml-1 ' href={`/store-info/`+ store.permalink } style={{ width: "99%" }} >
            <img 
              width={350} height={156}
              className='mx-auto cursor-pointer border-rounded'
              src={'/images/store-halal/350/' + _.get(store, 'SpreeCountry.country_code') + '.jpg'}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultProductImage;
              }}
              alt={store.store_name || 'store aspenku'} />
              </a>
              <div className='d-flex align-items-center justify-content-between my-2 mx-auto' style={{ width: '350px' }} >
                <span>{_.get(store, 'SpreeCountry.country_name')}</span>
                <img className='shadow' width='30' alt='flag' src={`/images/flags/${_.get(store, 'SpreeCountry.country_code')}.svg`} title={_.get(store, 'SpreeCountry.country_name')} />
              </div>
          </div>
        ))}
        </div>
        { this.state.skipStoreList > 0 ? 
        <div className='position-absolute bg-white rounded-circle shadow angle-slider angle-left' onClick={() => this.onSwipeStores(-12)} >
          <span className='fa fa-angle-left' ></span>
        </div> : '' }
        { (this.state.totalStore - this.state.skipStoreList) >= 12 ?
        <div className='position-absolute bg-white rounded-circle shadow angle-slider angle-right' onClick={() => this.onSwipeStores(12)} >
          <span className='fa fa-angle-right' ></span>
        </div> : '' }
      </div> 
    )
  }

  renderStoreMobile = () => {
    const { storesList } = this.state
    return( <div className='container position-relative storelist' >
        <div className='row d-inline-block store-scroll-horizontal pl-3' >            
        {storesList.map((store, key) => (<>
          <div  className='d-inline-flex  flex-column' style={{ width: "250px", height: "250px" }} key={key} >
          <a className='d-flex' href={`/store-info/`+ store.permalink } >
            <img 
              width={350} height={156}
              className='mx-auto cursor-pointer border-rounded'
              src={'/images/store-halal/350/' + _.get(store, 'SpreeCountry.country_code') + '.jpg'}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultProductImage;
              }}
              alt={store.store_name || 'store aspenku'} />
          </a>
          <div className='d-flex align-items-center justify-content-between my-2 mx-auto' style={{ width: '350px' }} >
                <span>{_.get(store, 'SpreeCountry.country_name')}</span>
                <img className='shadow' width='30' alt='flag' src={`/images/flags/${_.get(store, 'SpreeCountry.country_code')}.svg`} title={_.get(store, 'SpreeCountry.country_name')} />
              </div>
          </div>
        </>)) }
        </div>
      </div> 
    )
  }

  handleAddIntoWishlist = (product) => {
    if (global.getAuthToken()) {
      this.props.AddIntoWishlist(product).then(() => {
      });
    } else {
      this.setState({
        showModal: true,
      });
    }
  };

  handleRemoveFromWishlist = (productId) => {
    this.props.RemoveFromWishlist(productId).then(() => {});
  };
  hideModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  onClickSubCategory = (subCategory) => {
    const { category } = this.state
    this.props.history.push({
      pathname: "/category/" + category.permalink + "/" + subCategory.permalink,
      state: {
        categoryId: category.id,
        subCategoryId: subCategory.id,
        subCategoryName: subCategory.sub_category_name
      }
    });
  }

  render() {
    const { totalRow, products, limit, sortBy } = this.state

    return (<div className='container-category'>
      <div className="divider d-block d-sm-none"></div>
{/* 
      <section className='container-fluid bg-white shadow pt-2'>
        <div className='row'>
          <div className='container d-md-flex align-items-center' >
            <button className='btn btn-light btn-sm py-2 breadcrumb-category ' >
              <h6 className="mb-0">All Product</h6>
            </button>
            <span className='fa fa-angle-right'></span>
            <button className='btn btn-light btn-sm py-2 breadcrumb-category ' 
            onClick={() => this.props.history.push('/category/'+ category.permalink ) }
            >
              <h6 className="mb-0">{ category.category_name }</h6>
            </button>
            { subCategoryName ? <>
            <span className='fa fa-angle-right'></span>
            <button className='btn btn-light btn-sm py-2 breadcrumb-category ' >
              <h6 className="mb-0">{ subCategoryName }</h6>
            </button>
            </> : '' }
            <div className="btn-group ml-auto">
              <button type="button" className="btn btn-light btn-sm dropdown-toggle other-subcategory font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <h6 className="mb-0">Other<span className='fa mx-2 fa-angle-down' ></span>
              </h6>
              </button>
              <div className="dropdown-menu  dropdown-menu-right shadow border-0">
                {
                  subCategories.filter(c => c.sub_category_name !== subCategoryName).map((cat, index) => (
                    <button className="btn btn-light btn-sm  dropdown-item cursor-pointer" onClick={() => this.onClickSubCategory(cat)}   key={index}>
                      { cat.sub_category_name }
                    </button>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section> */}
<br />
      <section className='container mt-4' >
        <div className='row' >
          {this.renderHeaderSlider()}
          {/* {this.renderSideBanner()} */}
        </div>
      </section>

      <section className='container my-4' >
        <div className='row' >
          <div className='col' >
            <div className='bg-white border-rounded border pb-2' >
              <div className='d-flex align-items-center justify-content-between  border-bottom'>
                <h5 className='px-3 pt-3 pb-2' style={{ color: 'var(--orange)' }}>Halal Shopping Week’s Stores</h5>
                {/* <button className='btn btn-sm btn-light mx-3 bg-transparent' style={{ color: 'var(--orange)' }}>Lihat semua <em className='fa fa-angle-right ml-2' /> </button> */}
              </div>
              {window.screen.width > 576 ? this.renderStoreDesktop() : ''}
              {window.screen.width <= 576 ? this.renderStoreMobile() : '' }
            </div>
          </div>
        </div>
      </section>

      <section className='container my-4' >
        <div className='row '  >
          <div className='col-12' >
            <div className='bg-white border-rounded-top border-top border-right border-left' >
              <div className='d-md-flex align-items-center justify-content-between '>
                <h5 className='px-3 py-4' style={{ color: 'var(--orange)' }}>Recomended Product</h5>
                <div className="align-items-center px-3 d-flex pb-2">
                  <label style={{ width: "100px" }}>Sort By</label>
                  <Select
                    searchable={false}
                    name="sortBy"
                    options={sortByOptions}
                    controlStyles={{
                      height: "30px",
                      width: "155px",
                    }}
                    placeholder="--Select--"
                    value={sortBy}
                    onChange={this.sortChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col" >
            <div id='store-halal' className='bg-white border-rounded-bottom featured border-right border-left border-bottom' style={{ margin: "0" , padding: "10px 15px" }} >
            <PaginationProductCard
              products={products}
              changeLimitAndSkip={this.changeLimitAndSkip}
              totalRow={totalRow}
              limit={limit}
              className="category-featured"
              handleRemoveFromWishlist={this.handleRemoveFromWishlist}
              handleAddIntoWishlist={this.handleAddIntoWishlist}
              hideModal={this.hideModal}
            />
          </div>
          </div>
        </div>

      </section>

      <div className="divider d-block"></div>
      {this.state.showModal && <Login hideModal={this.hideModal}></Login>}
    </div>)
  }
  
}

const mapState = state => {
  return {
    categories: state.categories.categoriesList
  }
}

export default withRouter(
  connect( mapState, {
    getHalalPageBanner,
    getProductsHalal,
    getStoreListHalal,
    RemoveFromWishlist,
  })(Category)
);
