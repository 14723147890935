import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import defaultProductImage from "../../../assets/img/default_product_image.png";
import $ from "jquery";
import _ from "lodash";
import Rating from "react-rating";
import global from "../../../global";
import { AddIntoWishlist, RemoveFromWishlist } from "../../../actions";
import ReactTooltip from 'react-tooltip';
import "./ProductCard.scss";
class ProductCard extends Component {
  state = {
    wishlistProducts: [],
    showModal: false,
  };
  componentDidMount = () => {
    this.setState({
      wishlistProducts: this.props.wishlistProducts,
    });
    $("#id_" + this.props.product.id + " .img-fluid").css(
      "height",
      $("#id_" + this.props.product.id).width()
    );
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.wishlistProducts !== nextProps.wishlistProducts) {
      this.setState({
        wishlistProducts: nextProps.wishlistProducts,
      });
    }
  };

  handleImageClick = () => {
    let { product, manageProduct } = this.props;
    
    if (product.product_state === "published" || manageProduct) {
      return "/product-detail/" + encodeURI(this.props.product.permalink + '')
    }
      
    return '#'
  };


  render() {
    let {
      product,
      imgClassName,
      userStoreProduct,
      manageProduct,
      currencySymbol,
      rate,
      wishlist,
      wishlistProducts,
      userStore,
    
    } = this.props;
    // let stars = [1, 2, 3, 4, 5],
    //   classname = "";
    let imageUrl = defaultProductImage,
      salePrice = "";
    if (product.SpreeProductImages) {
      imageUrl =
        product.SpreeProductImages.length > 0
          ? // ? global.config.imageUrl + product.SpreeProductImages[0].main_image
            global.config.imageUrl +
            product.SpreeProductImages[0].thumbnail_image
          : defaultProductImage;
    } else {
      imageUrl = defaultProductImage;
    }
    if (product.SpreeProductPrices.length > 0) {
      // let lastIndex = SpreeProductPrices.length - 1;
      if (product.SpreeProductPrices.length > 1) {
        salePrice = global.calculateMinMaxSalePrice(product.SpreeProductPrices);
        salePrice =
          global.calculateCurrencyValue(salePrice[0], rate, currencySymbol) +
          " - " +
          global.calculateCurrencyValue(salePrice[1], rate, currencySymbol);
      } else {
        salePrice = global.calculateCurrencyValue(
          product.SpreeProductPrices[0].sale_price,
          rate,
          currencySymbol
        );
      }
    } else {
      if (Number(product.sell_price) === 0) {
        // salePrice = `${_.trim(currencySymbol)}. Contact Seller`;
        salePrice = `Contact Seller`;
      } else {
        salePrice = global.calculateCurrencyValue(
          product.sell_price,
          rate,
          currencySymbol
        );
      }
    }
    const Rate = (props) => {
      return <span className={`fa fa-star ${props.className}`} />;
    };
    const randomId = Math.random().toString()
    const country_code = _.get(product, 'SpreeStore.SpreeCountry.country_code')
    const country_name = _.get(product, 'SpreeStore.SpreeCountry.country_name')

    return (
      <>
        {/* {this.props.showModal && <Login hideModal={this.props.hideModal}></Login>} */}
        <div className="product">
          <div className={`box-img is-relative ${product.is_moderated === 0 ? 'opacity-05' : ''}`} id={"id_" + product.id}>
            {/* <NavLink to={userStoreProduct ? " " : "/product-detail/" + productId}> */}
            <NavLink to={ this.handleImageClick() }>
            <img
              className={"img-fluid " + (imgClassName ? imgClassName : "")}
              // style={{
              //   height: $(".box-img").width()
              //   // height: $("#id_" + product.id).width()
              // }}
              src={imageUrl}
              alt="product"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultProductImage;
              }}
              // onClick={() => this.handleImageClick()}
            />
            </NavLink>
            {!userStore && (
              <div className="like">
                {wishlist ? (
                  <span
                    className="fa fa-heart heart-checked"
                    onClick={() =>
                      this.props.handleRemoveFromWishlist(product.id)
                    }
                  />
                ) : !_.isEmpty(wishlistProducts) &&
                  !_.isEmpty(_.filter(wishlistProducts, { id: product.id })) ? (
                  <span
                    className="fa fa-heart heart-checked"
                    onClick={() =>
                      this.props.handleRemoveFromWishlist(product.id)
                    }
                  ></span>
                ) : (
                  <span
                    className="fa fa-heart-o"
                    onClick={() => this.props.handleAddIntoWishlist(product)}
                  ></span>
                )}
              </div>
            )}
          </div>

          <div className='d-flex justify-content-between align-items-center' > 
            <div className={`rating ${product.is_moderated ? '' : 'opacity-05'}`}>
            <Rating
              placeholderRating={parseFloat(product.average_rating)}
              placeholderSymbol={<Rate className="checked" />}
              emptySymbol={<Rate className="unchecked" />}
              fullSymbol={<Rate className="checked" />}
              readonly={true}
              quiet={true}
            />
            {product.SpreeStore && product.SpreeStore.store_review_count
              ? "(" + product.SpreeStore.store_review_count + ")"
              : ""}
          </div>
            {country_code ? <img className='shadow' alt='flag' width='20' src={`/images/flags/${country_code}.svg`} title={country_name} /> : '' }
          </div>
          {product.SpreeStore && product.SpreeStore.store_name ? (
            <div className="toko">
              <NavLink to={"/store-info/" + encodeURI(product.SpreeStore.permalink + '') }>
                {product.SpreeStore.store_name}
              </NavLink>
              {product.SpreeStore.is_verified ? (
                <i className="fa fa-check-circle verified-store ml-1"></i>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className={`name ${product.is_moderated === 0 ? 'opacity-05' : '' }`} >
            {" "}
            <NavLink to={ this.handleImageClick() } >
              {product.name}
            </NavLink>
            {(manageProduct || userStore) &&
            product.product_state === "published" ? (
              <i className="fa fa-check-circle verified-store ml-1"></i>
            ) : (
              ""
            )}
          </div>
          <div className={`price ${product.is_moderated === 0 ? 'opacity-05' : '' }`}>
            {salePrice}
            <span className="ml-2 measure-qty-msg">{`${
              product.unit_measure ? "/ " + product.unit_measure : ""
            } `}</span>
          </div>
          {userStoreProduct && manageProduct && ( <>
            <div className="text-center pt-4 product-actions-wrapper d-flex">
              {/* <button className="round-icon-btns" onClick={() => this.props.viewProduct(productId)}> */}
              <button
                data-tip data-for="btn-view"
                className="round-icon-btns"
                onClick={() => this.props.history.push("/product-detail/" + encodeURI(this.props.product.permalink + '')) }
              >
                <i className="fa fa-eye" />
              </button>
              <ReactTooltip id='btn-view' ><span>View</span></ReactTooltip>
              <button
                data-tip data-for="btn-edit"
                className="round-icon-btns"
                onClick={() =>
                  this.props.manageEditProductBtnClick("edit", product.id)
                }
              >
                <i className="fa fa-pencil" />
              </button>
              <ReactTooltip id='btn-edit' ><span>Edit</span></ReactTooltip>
              <button
                data-tip data-for="btn-delete"
                className="round-icon-btns"
                onClick={() => this.props.deleteProduct("delete", product.id)}
              >
                <i className="fa fa-trash" />
              </button>
              <ReactTooltip id='btn-delete' ><span>Delete</span></ReactTooltip>
              {product.is_moderated === 1 ? <>
                {
                  product.product_state === "unpublished" ? <>
                <button 
                  data-tip data-for={`btn-publish `+ randomId } className="round-icon-btns" 
                  onClick={() => this.props.handleProductState( "published" , product.id ) } >
                  <i className="fa fa-check" />
                </button>
                <ReactTooltip id={`btn-publish `+ randomId }  ><span>Publish</span></ReactTooltip> 
              </> : <>
                  <button
                    data-tip data-for={`btn-unpublish `+ randomId }
                    className="round-icon-btns"
                    onClick={() => this.props.handleProductState( "unpublished", product.id ) } >
                    <i className="fa fa-exclamation-triangle" />
                  </button>
                  <ReactTooltip id={`btn-unpublish `+ randomId } ><span>Unpublish</span></ReactTooltip>
                </> } </> : (<>
                <button data-tip data-for="btn-moderating" className="round-icon-btns bg-dark" >
                  <i className={`fa fa-lock`} />
                </button>
                <ReactTooltip id='btn-moderating' ><span>In Moderation</span></ReactTooltip>
              </>) }
            </div>
            { product.is_moderated !== 1 ? <div>
              <span className="badge badge-secondary font-weight-normal mt-2" >In Moderation</span>
            </div> : ''}
          </>)}

          {wishlist && (
            <div className="text-center pt-3">
              <button
                type="button"
                className="button btn-buy"
                onClick={() => this.props.handleBuyButton(product.permalink)}
              >
                BUY
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  rate: state.exchangeRate.rate,
  currencySymbol: state.exchangeRate.currencySymbol,
  wishlistProducts: state.product.wishlistProducts,
});

export default withRouter(
  connect(mapStateToProps, { AddIntoWishlist, RemoveFromWishlist })(ProductCard)
);

// let minPrice,
//   maxPrice,
//   prices = [];
// SpreeProductPrices.map(price => {
//   prices = [...prices, price.sale_price];
//   return prices;
// });
// minPrice = Math.min(...prices);
// maxPrice = Math.max(...prices);
//----------------//
// let prices = SpreeProductPrices;
// let length = prices.length;
// let maxPrice = prices[length - 1].sale_price,
//   minPrice = prices[0].sale_price;
// for (let i = 0; i < length; i++) {
//   if (prices[i].sale_price < maxPrice) {
//     if (prices[i].sale_price <= minPrice) {
//       minPrice = prices[i].sale_price;
//     }
//   } else {
//     maxPrice = prices[i].sale_price;
//   }
// }
// salePrice = "$ " + minPrice + " - $ " + maxPrice;
// salePrice =
//   "$ " +
//   SpreeProductPrices[0].sale_price +
//   " - " +
//   "$ " +
//   SpreeProductPrices[lastIndex].sale_price;
