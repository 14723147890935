import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/bootstrap-4-navbar.css";
import "../src/assets/js/aspenku.js";
import "../src/assets/js/bootstrap-4-navbar.js";
import store from "./Utilities/configStore";
ReactDOM.render(<App store={store} />, document.getElementById("root"));
serviceWorker.unregister();
