import { STORE ,  SELECTED_MENU_STORE, SET_USER_STORE } from "./typesOfActions";
import { postStoreProfilingAPI , getStoreProfilingAPI, putStoreProfilingAPI } from '../service_api/storeProfilingService';
// export const getStore = () => (dispatch, getState, Api) => {
//   return Api.get("users/stores").then(response => {
//     if (response.statusCode === 200) {
//       dispatch({
//         type: STORE,
//         data: response.data
//       });
//     }
//     return response;
//   });
// };
// export const createStore = payload => (dispatch, getState, Api) => {
//   return Api.post("users/stores", payload).then(response => {
//     if (response.statusCode === 200) {
//       dispatch(getStore());
//     }
//     // else {
//     return response;
//     // }
//   });
// };
// export const updateStore = payload => (dispatch, getState, Api) => {
//   return Api.put("users/stores", payload).then(response => {
//     if (response.statusCode === 200) {
//       dispatch(getStore());
//     }
//     return response;
//   });
// };

export const storeDocuments = () => (dispatch, getState, Api) => {
  return Api.get("users/stores/document").then(response => {
    return response;
  });
};

export const verifyStore = payload => (dispatch, getState, Api) => {
  return Api.post("users/stores/document", payload).then(response => {
    if (response.statusCode === 200) {
      dispatch(getStore());
    }
    return response;
  });
};

export const reVerifyStore = payload => (dispatch, getState, Api) => {
  return Api.put("users/stores/document", payload).then(response => {
    if (response.statusCode === 200) {
      dispatch(getStore());
    }
    return response;
  });
};

export const getUserProducts = payload => (dispatch, getState, Api) => {
  let sort = "";
  if (payload.sortBy) {
    sort = `&sort=${payload.sortBy}`;
  }
  return Api.get(
    "users/products?limit=" + payload.limit + "&skip=" + payload.skip + sort
  ).then(response => {
    return response;
  });
};

export const getStoreDetails = payload => (dispatch, getState, Api) => {
  return Api.get("stores/" + payload.permalink, "basicAuth", 'v3' ).then(response => {
    return response;
  });
};

export const changeStoreState = () => (dispatch, getState, Api) => {
  return Api.put("users/stores/state/toggle", {}).then(response => {
    return response;
  });
};

export const getStoreListCategory = payload => (dispatch, getState, Api) => {
  const { category_id, limit = 12 , skip = 0 } = payload
  return Api.get(`category/brandList?category_id=${category_id}&limit=${limit}&skip=${skip}` , "basicAuth", 'v3' ).then(response => {
    return response;
  });
};

/* -- */

export const getStore = () => (dispatch) => {
  return getStoreProfilingAPI()
    .then(res => res.data)
    .then(response => {
      if (response.statusCode === 200) {
        dispatch({
          type: STORE,
          data: response.data
        });
      }
      return response;
  });
};
export const createStore = payload => (dispatch, getState, Api) => {
  return postStoreProfilingAPI(payload).then(response => {
    if (response.statusCode === 200) {
      dispatch(getStore());
    }
    return response;
  });
};
export const updateStore = payload => (dispatch, getState, Api) => {
  return putStoreProfilingAPI(payload).then(response => {
    if (response.statusCode === 200) {
      dispatch(getStore());
    }
    return response;
  });
};
export const selectMenuStore = (selectedMenu) => (dispatch ) => {
  console.log('store',selectedMenu)
  dispatch({ type: SELECTED_MENU_STORE , selectedMenu })
};

export const setUserStore = (userStore) => (dispatch ) => {
  dispatch({ type: SET_USER_STORE , userStore })
};

export const getStoreListHalal = payload => (dispatch, getState, Api) => {
  const { limit = 12 , skip = 0 } = payload
  return Api.get(`storesList/halal?limit=${limit}&skip=${skip}` , "basicAuth", 'v3' ).then(response => {
    return response;
  });
};
