import React, { Component } from "react";
import img24 from "../../../../assets/img/img-24.svg";
import imgreturn from "../../../../assets/img/img-return.svg";
import imgsecure from "../../../../assets/img/img-secure.svg";
class HomePageFooterContent extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="level more-feature">
                        <div className="level-item">
                            <img src={img24} className="img-fluid" alt="Support"></img>
                            <div className="content">
                                <div className="title">24/7 Support</div>
                                <div className="subtitle">Support online 24 hours day</div>
                            </div>
                        </div>
                        <div className="level-item">
                            <img src={imgreturn} className="img-fluid" alt="Free Return" />
                            <div className="content">
                                <div className="title">Free Return</div>
                                <div className="subtitle">30 days money back guarantee</div>
                            </div>
                        </div>
                        <div className="level-item">
                            <img src={imgsecure} className="img-fluid" alt="Secure Payment" />
                            <div className="content">
                                <div className="title">Secure Payment</div>
                                <div className="subtitle">100% secure payment</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
            </React.Fragment>
        )
    }
}

export default HomePageFooterContent;