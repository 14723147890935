export const midtrans = payload => (dispatch, getState, Api) => {
  return Api.post("midtrans/snapToken", payload , null , 'v3').then(response => {
    return response;
  });
};
export const midtransCreditCard = payload => (dispatch, getState, Api) => {
  return Api.post("midtrans/creditCard", payload , null , 'v3').then(response => {
    return response;
  });
};
export const getCardToken = (params) => (dispatch, getState, Api) => {
  return Api.get("midtrans/token?gross_amount=" + params.gross_amount +
    "&card_number=" + params.card_number +
    "&card_exp_month=" + params.card_exp_month + "&card_exp_year=" + params.card_exp_year +
    "&card_cvv=" + params.card_cvv, null , "v3" ).then(
      response => {
        return response;
      })
}
export const getCardTokenCc = (params) => (dispatch, getState, Api) => {
  return Api.get("midtrans/tokenCC?&card_number=" + params.card_number +
    "&card_exp_month=" + params.card_exp_month + "&card_exp_year=" + params.card_exp_year +
    "&card_cvv=" + params.card_cvv, null , "v3" ).then(
      response => {
        return response;
      })
}
export const getChargesCardMYS = (params) => (dispatch, getState, Api) => {

  const formData = new FormData()
  formData.append('number', params.card_number)
  formData.append('exp_month', params.card_exp_month)
  formData.append('exp_year', params.card_exp_year)
  formData.append('cvc', params.card_cvv)
  formData.append('order_id', params.order_id)

  return Api.post("stripe/chargesCard", formData, "formData" , "v3" )
}
export const bankTransferConfirmation = (payload, orderId) => (
  dispatch,
  getState,
  Api
) => {
  return Api.put("users/order/" + orderId + "/confirm", payload , null , 'v3' ).then(
    response => {
      return response;
    }
  );
};
