import React, { Component } from "react";
import "./Loader.scss";
export default class Loader extends Component {
  render() {
    return (
      <div
        id="loader"
      >
        <div className="lds-dual-ring"></div>
        {/* <div className="loader">
          <span className="spinner"></span>
        </div> */}
      </div>
    );
  }
}
