import { ACTIVESUBCATEGORY } from "../actions/typesOfActions";
const intialState = {
    activeSubCategory: null
};
export default function (state = intialState, action) {
    switch (action.type) {
        case ACTIVESUBCATEGORY:
            return {
                ...state,
                activeSubCategory: {
                    subCategoryId: action.subCategoryId,
                    subCategoryName: action.subCategoryName
                }
            };
        default:
            return state;
    }
}
