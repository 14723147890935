import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { exportProgram } from "../../actions/exportProgram";
import { getProvinces, getCities } from '../../actions';
import global from '../../global';
import { Helmet } from 'react-helmet';
import './sitemap.scss';


class Contact extends Component {
    static contextType = global.loaderContext;



    componentDidMount = () => {
        this.context.hideLoader();
    };






    render() {
        return (<>
            <Helmet>
                <title>{'Sitemap - Aspenku'}</title>
                <meta name="description" content={'Sitemap - Aspenku'} />
                <meta name="keywords" content={'Sitemap - Aspenku'} />
                <meta name="title" content={'Sitemap - Aspenku'} />
            </Helmet>
            <div className="container cart">
                <div className="news-detail">

                    <div className="description mt-2">
                        <div className="font-title">
                            <h2><strong><p>Sitemap</p></strong></h2>
                        </div>
                        <div className='font-desc'>
                            <div id='sitemap' class="container">

                                <section id="sec1">

                                    <h3 className='my-4' ><a href="/">Home</a></h3>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <ul>
                                                <li>Support
                                                    <ul>
                                                        <li><a href="/contact">Contact</a></li>
                                                        <li><a href="/how-to-buy">How to Buy</a></li>
                                                        <li><a href="/how-to-sell">How to Sell</a></li>
                                                        <li><a href="/footer-tender">Tender</a></li>
                                                        <li><a href="/career">Career</a></li>
                                                        <li><a href="/agency">Verified Merchant</a></li>
                                                        <li><a href="/formulir">Formulir Ekspor Barengan</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-3">
                                            <ul>
                                                <li>Company Information
                                                    <ul>
                                                        <li><a href="/about">About</a></li>
                                                        <li><a href="/blogs">Blog</a></li>
                                                        <li><a href="/term-and-condition">Terms & Conditions</a></li>
                                                        <li><a href="/faq">FAQ</a></li>
                                                        <li><a href="/partner-program">Partner Program</a></li>
                                                        <li><a href="/fda-registration">FDA Registration</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-3">
                                            <ul>
                                                <li><a href="/halal-product">Halal Product</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                                <section id="sec2">

                                    <h3 className='my-4' >Product</h3>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <ul>
                                                <li><a href="/product-detail/:permalink" onClick={e => e.preventDefault()} >Product Detail</a></li>
                                                <li><a href="/search-product">Serch Product</a></li>
                                                <li><a href="/category/:permalink" onClick={e => e.preventDefault()} >Category</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                                <section id="sec3">

                                    <h3 className='my-4' >Store</h3>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <ul>
                                                <li><a href="/store-info/:permalink" onClick={e => e.preventDefault()} >Store Detail</a></li>
                                                <li><a href="/store-info/:permalink#main-profile" onClick={e => e.preventDefault()} >Main Profile</a></li>                                               
                                                <li><a href="/store-info/:permalink#merchant-information" onClick={e => e.preventDefault()} >Merchant's Information</a></li>                                               
                                                <li><a href="/store-info/:permalink#products" onClick={e => e.preventDefault()} >Products</a></li>                                               
                                            </ul>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }
}


export default withRouter(connect(null, {
    exportProgram,
    getProvinces,
    getCities
})(Contact));