import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";
import global from "../../../global";
import { loginApi } from "../../../actions";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
global.firebaseInitialization();
class SocialLogin extends Component {
  state = {
    fbDisabled: false,
    googleDisabled: false
  };
  responseFacebook = response => {
    this.setState({ fbDisabled: true }, () => {
      this.props.setLoaderAndDisable("facebook");
    });
    if (response.accessToken) {
      let email = response.email;
      let accessToken = response.accessToken;
      if (!email) {
        this.setState({ fbDisabled: true });
        this.props.setErrorMessage("danger", "Didn't get your email.");
      } else if (accessToken) {
        this.handleFacebookProvider(accessToken);
      }
    } else {
      this.setState({ fbDisabled: false }, () => {
        this.props.setLoaderAndDisable("facebook");
      });
    }
  };
  handleFacebookProvider = async accessToken => {
    let credential = await firebase.auth.FacebookAuthProvider.credential(
      accessToken
    );
    if (credential) {
      await firebase
        .auth()
        .signInWithCredential(credential)
        .then(async response => {
          let uid = response.user.uid;
          let getUserInfo = await firebase.auth().currentUser;
          if (getUserInfo) {
            let authentication_Token = await getUserInfo.getIdToken();
            this.handleSubmit(uid, authentication_Token);
          }
        })
        .catch(error => {
          this.props.setLoaderAndDisable("facebook");
          this.props.setErrorMessage("danger", error.message);
        });
    }
  };
  responseGoogle = response => {
    this.setState({ googleDisabled: true }, () => {
      this.props.setLoaderAndDisable("google");
    });
    if (response.tokenObj) {
      let tokenObject = response.tokenObj;
      let accessToken = tokenObject.access_token;
      let idToken = tokenObject.id_token;
      if (accessToken && idToken) {
        this.handleGoogleProvider(accessToken, idToken);
      }
    } else {
      this.setState({ googleDisabled: false }, () => {
        this.props.setLoaderAndDisable("google");
      });
    }
  };
  handleGoogleProvider = async (accessToken, idToken) => {
    let credential = await firebase.auth.GoogleAuthProvider.credential(
      idToken,
      accessToken
    );
    if (credential) {
      await firebase
        .auth()
        .signInWithCredential(credential)
        .then(async response => {
          let uid = response.user.uid;
          let getUserInfo = firebase.auth().currentUser;
          if (getUserInfo) {
            let authentication_Token = await getUserInfo.getIdToken();
            this.handleSubmit(uid, authentication_Token);
          }
        })
        .catch(error => {
          this.props.setLoaderAndDisable("google");
          this.props.setErrorMessage("danger", error.message);
        });
    }
  };
  handleSubmit = (uid, authentication_Token) => {
    let payload = {
      uid: uid,
      authentication_token: authentication_Token
    };
    this.props.loginApi(payload).then(response => {
      if (response.statusCode === 200) {
        if (global.getAuthToken()) {
          if (response.data.userType === "User") {
            let currentPath = this.props.history.location.pathname;
            currentPath === "/register"
              ? this.props.history.push("/")
              : this.props.hideModal();
          } else if (response.data.userType === "Admin") {
            window.open(
              global.config.adminLoginPageUrl + global.getAuthToken(),
              "_self"
            );
          }
        }
      } else {
        this.props.setLoaderAndDisable();
        this.props.setErrorMessage(
          "danger",
          response.statusCode === 400
            ? global.errors.error400
            : response.message
        );
      }
    });
  };
  // manualFacebookLogin = () => {
  //   let provider = new firebase.auth.FacebookAuthProvider();
  //   firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then(async result => {
  //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //       var token = await result.credential.accessToken;
  //       this.handleFacebookProvider(token);
  //       // The signed-in user info.
  //       var user = result.user;
  //       // ...
  //     })
  //     .catch(function (error) {
  //       // Handle Errors here.
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       // The email of the user's account used.
  //       var email = error.email;
  //       // The firebase.auth.AuthCredential type that was used.
  //       var credential = error.credential;
  //       // ...
  //     });
  // };
  render() {
    let { fbDisabled, googleDisabled } = this.state;
    return (
      <React.Fragment>
        {/* <button onClick={this.manualFacebookLogin}>Facebook</button> */}
        <FacebookLogin
          appId={global.socialLogin.facebookAppId}
          autoLoad={false}
          fields="name,email,picture"
          callback={this.responseFacebook}
          isDisabled={fbDisabled}
          render={this.props.facebookDesign}
        />
        <GoogleLogin
          clientId={global.socialLogin.googleClientId}
          buttonText="Login"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          render={this.props.googleDesign}
          disabled={googleDisabled}
        />
      </React.Fragment>
    );
  }
}
export default withRouter(connect(null, { loginApi })(SocialLogin));
