import React from 'react'
export default class MessageAlert extends React.Component {
    componentDidMount = () => {
        this.alert.scrollIntoView();
        this.props.handleAlertClose && setTimeout(() => {
            this.props.handleAlertClose();
        }, 12000);
    }

    render() {
        let { alertType, message, hideCloseBtn, children } = this.props;
        // let classes = modalAlert ?
        //     "alert alert-dismissable alert-" + alertType
        //     :
        //     "d-block myadmin-alert myadmin-alert-icon myadmin-alert-click myadmin-alert-top alerttop alert-" + alertType
        return (

            <div ref={ref => this.alert = ref} className={"medium-font alert alert-dismissible alert-" + alertType} role="alert">
                <span>
                    {message}
                </span>
                {
                    !hideCloseBtn &&
                    <button type="button" className="close" onClick={this.props.handleAlertClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                }
                {children}
            </div>
            // <div className={classes}>
            //     <p className="close m-b-0" onClick={() => this.props.handleAlertClose()}>×</p>
            //     <b>
            //         {message}
            //     </b>
            // </div>
        )
    }
}