export const getBlogs = payload => (dispatch, getState, Api) => {
  let apiUrl = "page?limit=" + payload.limit + "&skip=" + payload.skip;
  if (payload.searchText) {
    apiUrl = apiUrl + "&search=" + payload.searchText;
  }
  if (payload.categoryId) {
    apiUrl = apiUrl + "&page_category_id=" + payload.categoryId;
  }
  return Api.get(apiUrl, "basicAuth").then(response => {
    return response;
  });
};
export const getBlogDetail = id => (dispatch, getState, Api) => {
  return Api.get("pages/" + id, "basicAuth", 'v3').then(response => {
    return response;
  });
};
export const getCategoriesOfBlogs = () => (dispatch, getState, Api) => {
  return Api.get("pageCategory", "basicAuth").then(response => {
    return response
  })
}