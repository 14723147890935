import {
  PRODUCTSEARCHTEXT,
  WISHLIST,
  ADDPRODUCTINWISHLIST,
  REMOVEPRODUCTFROMWISHLIST
} from "../actions/typesOfActions";
const intialState = {
  productSearchText: null,
  wishlistProducts: []
};
export default function(state = intialState, action) {
  switch (action.type) {
    case PRODUCTSEARCHTEXT:
      return {
        ...state,
        productSearchText: action.searchText
      };
    case WISHLIST:
      return {
        ...state,
        wishlistProducts: action.data
      };
    case ADDPRODUCTINWISHLIST:
      return {
        ...state,
        wishlistProducts: [action.data, ...state.wishlistProducts]
      };
    case REMOVEPRODUCTFROMWISHLIST:
      return {
        ...state,
        wishlistProducts: state.wishlistProducts.filter(
          product => product.id !== action.productId
        )
      };
    default:
      return state;
  }
}
