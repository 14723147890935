import React from 'react';
import './Error404.scss';
class Error404 extends React.Component {
    render() {
        let { hideError404, message } = this.props;
        return (
            <div className="error404-container">
                <i className="fa fa-exclamation-triangle errorImg"></i>
                {!hideError404 && <div className="error404"><b>Error 404</b></div>}
                <div className="errorMsg">
                    {message}
                </div>
            </div >
        )
    }
}
export default Error404;