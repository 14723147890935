import { ADDTOCART } from "../actions/typesOfActions";
const intialState = {
  cartInfo: []
};
export default function(state = intialState, action) {
  switch (action.type) {
    case ADDTOCART:
      return {
        ...state,
        cartInfo: action.data
      };
    default:
      return state;
  }
}
