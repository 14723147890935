export const sosial = [
    {
        id:"1",
        img:require("../../../assets/img/sosial/fb.webp"),
        path:"https://www.facebook.com/IndonesianExportMarketplace"
    },
    {
        id:"2",
        img:require("../../../assets/img/sosial/ig.webp"),
        path:"https://www.instagram.com/AspenkuInternational"
    },
    {
        id:"3",
        img:require("../../../assets/img/sosial/linked.webp"),
        path:"https://www.linkedin.com/company/aspenku"
    }
]

