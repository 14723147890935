import React from "react";
class TextInput extends React.Component {
  render() {
    let {
      autoComplete,
      className,
      label,
      type,
      placeholder,
      name,
      value,
      disabled,
      step,
      index,
      maxLength,
      helperText,
      helperTextStyle
    } = this.props;

    return (
      <>
        {/* <div className="form-group"> */}
        {label && <label className={helperText ? "mb-0" : ""}>{label}</label>}
        {helperText ? (
          <span className="helper-text mb-2" style={helperTextStyle}>{helperText}</span>
        ) : (
          ""
        )}

        <input
          type={type}
          className={`form-control ${disabled ? " disabled-input " : " "} ${
            className ? className : " "
          }`}
          placeholder={placeholder}
          name={name}
          value={value}
          disabled={disabled}
          onChange={e => this.props.onChange(e, index)}
          step={step && step}
          maxLength={maxLength}
          autoComplete={autoComplete}
          onKeyDown={e => {
            this.props.onKeyPress && this.props.onKeyPress(e);
          }}
        />
        {/* {helperText ? <span className="helper-text">{helperText}</span> : ""} */}
        {/* </div> */}
      </>
    );
  }
}
export default TextInput;
