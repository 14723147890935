import { EXCHANGERATE, CHANGECURRENCY, SETCURRENCYFROMLOCALSTORAGE } from "./typesOfActions";

export const getExchangeRates = () => (dispatch, getState, Api) => {
    return Api.get("exchangeRates", "basicAuth").then(response => {
        if (response.statusCode === 200) {
            dispatch({
                type: EXCHANGERATE,
                data: response.data
            });
        }
        return response;
    });
};

export const changeCurrency = (currencyType) => (dispatch, getState, Api) => {
    dispatch({
        type: CHANGECURRENCY,
        currencyType
    })
};

export const setCurrencyFromLocalstorage = () => (dispatch, getState, Api) => {
    dispatch({
        type: SETCURRENCYFROMLOCALSTORAGE
    })
};
