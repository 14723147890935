import React, { Component } from "react";
//eslint-disable-next-line
import Select, { components } from "react-select";

// const menuHeaderStyle = {
//     padding: "8px 12px",
//     background: "white",
//     color: "black"
// };

// const MenuList = props => {
//     let selectProps = props.selectProps;
//     return (
//         <components.MenuList {...props}>
//             {props.children}
//             <div style={menuHeaderStyle}>
//                 <span
//                     className="w-100 text-center "
//                     style={{ color: "#454c53" }}
//                     onClick={(e) => selectProps.loadMore(e, selectProps.name)}
//                 >
//                     {"Load more " + selectProps.name}
//                 </span>
//             </div>
//         </components.MenuList>
//     );
// };

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

class SelectSearch extends Component {
  handleScrolledToBottom = () => {
    this.props.loadMoreData && this.props.loadMore(this.props.name);
  };
  renderFormatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  formatGroupLabel = () => (this.props.isGroup ?  {
    formatGroupLabel: this.renderFormatGroupLabel 
  } : {})
  render() {
    let {
      label,
      options,
      onInputChange,
      onChange,
      isClearable,
      placeholder,
      value,
      onMenuOpen,
      className,
      valuesIndent,
      isLoading
      // showLoadMoreButton
    } = this.props;
    return (
      <>
        {/* <div className="form-group"> */}
        {label && (
          <label
            className={`control-label text-right ${className ? className : ""}`}
          >
            {label}
          </label>
        )}
        <Select
          isSearchable={this.props.searchable}
          // components={showLoadMoreButton ? { MenuList } : {}}
          isDisabled={this.props.disabled}
          value={value}
          options={options}
          onInputChange={onInputChange}
          isClearable={isClearable}
          isLoading={isLoading}
          onChange={onChange}          
          placeholder={placeholder}
          onMenuScrollToBottom={() => this.handleScrolledToBottom()}
          onMenuOpen={onMenuOpen}
          {...this.formatGroupLabel() }
          styles={{
            container: base => ({
              ...base,
              display: "block",
              width: "100%"
            }),
            control: base => ({
              ...base,
              borderRadius: "6px",
              height: 46,
              borderColor: "#e0e0e0",
              ...(this.props.controlStyles && { ...this.props.controlStyles })
            }),
            singleValue: base => ({
              ...base,
              fontWeight: 400,
              fontSize: "14px",
              color: "#495057"
            }),
            placeholder: base => ({
              ...base,
              fontWeight: 400,
              fontSize: "14px"
            }),
            option: base => ({
              ...base,
              fontWeight: 500,
              fontSize: "14px",
              ...(valuesIndent && { textIndent : valuesIndent })
            }),
          }}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "silver",
              primary: "#233750"
            }
          })}
          {...this.props}
        />
        {/* </div> */}
      </>
    );
  }
}
export default SelectSearch;
// import React from "react";
// class Select extends React.Component {
//     render() {
//         let { options, selectText, noDataText, selectedOption, name, label, disabledSelectText, multiple } = this.props;
//         return (
//             <div className="form-group">
//                 {label && <label className="control-label text-right">{label}</label>}
//                 <select
//                     name={name}
//                     className="form-control"
//                     value={selectedOption}
//                     onChange={(e) => this.props.handleChange(e)}
//                     multiple={multiple}
//                 >
//                     {options.length === 0 ? <option value="" disabled>{noDataText}</option>
//                         :
//                         <>
//                             {selectText && <option value="" disabled={disabledSelectText}>{selectText}</option>}
//                             {options && options.map((data, index) => {
//                                 return <option key={index} value={data._id}>{data.name}</option>
//                             })}
//                         </>
//                     }
//                 </select>
//             </div>
//         )
//     }

// }
// export default Select;
