import {
  PRODUCTSEARCHTEXT,
  WISHLIST,
  ADDPRODUCTINWISHLIST,
  REMOVEPRODUCTFROMWISHLIST
} from "./typesOfActions";
import global from "../global";
export const getProductDetail = productId => (dispatch, getState, Api) => {
  return Api.get("users/products/" + productId).then(response => {
    return response;
  });
};
export const sendInquiry = payload => (dispatch, getState, Api) => {
  return Api.post("product/inquiry", payload).then(response => {
    return response;
  });
};

export const getSearchedProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  let url = "product?limit=" + payload.limit + "&skip=" + payload.skip;
  // if (payload.productByStore) {
  //   url += `$store_id=${payload.productByStore}`;
  // }
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sort=${payload.sortBy}`;
  }
  if (payload.price) {
    url += `&price=${JSON.stringify(payload.price)}`;
  }
  if (payload.category) {
    payload.category.map(category => {
      url += `&category=${category}`;
      return url;
    });
  }
  return Api.get(url, auth).then(response => {
    return response;
  });
};

export const getSearchedProductsByStore = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  let url = "product?limit=" + payload.limit + "&skip=" + payload.skip + "&store_id=" +
  payload.store_id;;
  if (payload.searchText) {
    url += `&search=${payload.searchText}`;
  }
  if (payload.sortBy) {
    url += `&sort=${payload.sortBy}`;
  }
  if (payload.price) {
    url += `&price=${JSON.stringify(payload.price)}`;
  }
  if (payload.category) {
    payload.category.map(category => {
      url += `&category=${category}`;
      return url;
    });
  }
  return Api.get(url, auth).then(response => {
    return response;
  });
};

export const getStoreProducts = payload => (dispatch, getState, Api) => {
  let auth = global.getAuthToken() ? null : "basicAuth";
  let url =
    "product?limit=" +
    payload.limit +
    "&skip=" +
    payload.skip +
    "&store_id=" +
    payload.store_id;
  if (payload.sortBy) {
    url += `&sort=${payload.sortBy}`;
  }
  return Api.get(url, auth).then(response => {
    return response;
  });
};

export const handleProductSearch = searchText => (dispatch, getState, Api) => {
  dispatch({
    type: PRODUCTSEARCHTEXT,
    searchText
  });
};
export const AddIntoWishlist = payload => (dispatch, getState, Api) => {
  return Api.post("wishlists", { product_id: payload.id }).then(response => {
    if (response.statusCode === 200) {
      dispatch({
        type: ADDPRODUCTINWISHLIST,
        data: payload
      });
      // dispatch(getWishlistProducts());
    }
    // return response;
  });
};
export const getWishlistProducts = () => (dispatch, getState, Api) => {
  return Api.get("wishlists").then(response => {
    if (response.statusCode === 200 && response.data.data.length > 0) {
      dispatch({
        type: WISHLIST,
        data: [
          ...new Set(response.data.data.map(product => product.SpreeProduct))
        ]
      });
    }
    return response;
  });
};
export const RemoveFromWishlist = productId => (dispatch, getState, Api) => {
  return Api.delete("wishlists?product_id=" + productId).then(response => {
    if (response.statusCode === 200) {
      dispatch({
        type: REMOVEPRODUCTFROMWISHLIST,
        productId: productId
      });
      // dispatch(getWishlistProducts());
    }
    // return response;
  });
};
export const getSpecifications = () => (dispatch, getState, Api) => {
  return Api.get("product/specification" , null , "v3" ).then(response => {
    return response;
  });
};