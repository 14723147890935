import { closePopupCreateStoreService } from "../service_api/userService";
import { GET_ADDRESSES } from "./typesOfActions";

export const getUserAddresses = () => (dispatch, getState, Api) => {
  return Api.get("users/addresses").then(response => {
    dispatch({
      type: GET_ADDRESSES,
      addresses: response.data
    })
    return response;
  });
};

export const addAddress = payload => (dispatch, getState, Api) => {
  return Api.post("users/addresses", payload).then(response => {
    return response;
  });
};

export const updateAddress = (id, payload) => (dispatch, getState, Api) => {
  return Api.put("users/addresses/" + id, payload).then(response => {
    return response;
  });
};

export const deleteAddress = id => (dispatch, getState, Api) => {
  return Api.delete("users/addresses/" + id).then(response => {
    return response;
  });
};

export const closePopupCreateStoreAction = (id) => () => {
  return closePopupCreateStoreService(id)
};