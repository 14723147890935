import React from "react";
import * as firebase from "firebase/app";
import momentTZ from "moment-timezone";
import _ from "lodash";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const firebaseConfig = {
  apiKey: "AIzaSyCDBJ8xmA7GNWinGvNEp2earaWyfXM59Jc",
  authDomain: "aspenku-apps.firebaseapp.com",
  databaseURL: "https://aspenku-apps.firebaseio.com",
  projectId: "aspenku-apps",
  storageBucket: "aspenku-apps.appspot.com",
  messagingSenderId: "516750850752",
  appId: "1:516750850752:web:71ce9c6ca663d5edbccb25"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyAz6HF1VoUm9YafEbQEoSjI5A89qEpCgDE",
//   authDomain: "chat-7e3d4.firebaseapp.com",
//   projectId: "chat-7e3d4",
//   storageBucket: "chat-7e3d4.appspot.com",
//   messagingSenderId: "945144509593",
//   appId: "1:945144509593:web:f9a782bebac74f31823408"
// };
const loaderContext = React.createContext();
let currentTimezone = momentTZ.tz.guess();

// Used to convert prices based on slected currency
let calculateCurrencyValue = (price, rate, currencySymbol) => {
  return currencySymbol + addComaSeparator(price * rate, currencySymbol);
};

let addComaSeparator = (priceString = 0, currencySymbol = "") => {
  let convertedString = "";
  if(currencySymbol.includes('Rp')){
    convertedString = Math.round(priceString)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }else {
    convertedString = priceString
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  return convertedString;
};

export default {
  config: {
    apiUrl: process.env.REACT_APP_API + "/api/v1/",
    apiUrlV2: process.env.REACT_APP_API + "/api/v2/",
    apiUrlV3: process.env.REACT_APP_API + "/api/v3/",
    imageUrl: process.env.REACT_APP_IMAGE_URL,
    adminLoginPageUrl: process.env.REACT_APP_ADMIN_LOGIN_URL,
    homePageUrl: process.env.REACT_APP_HOME_URL,
    gmapApi: process.env.REACT_APP_GMAP_API
  },
  chat: {
    whatsappChatUrl: "https://wa.me/628872913851",
    sendbirdAppId: "A9E41997-81D9-43CA-8731-601AB757BD73",
  },
  firebaseInitialization: () => {
    return !firebase.apps.length
      ? firebase.initializeApp(firebaseConfig)
      : firebase.app();
  },
  // ModalContext: React.createContext(),
  errors: {
    // error400: "Technical Error!!!"
    error400: "Connection to server timed out. Please check your connection or try again later."
  },
  socialLogin: {
    // facebookAppId: "844116769316407",
    facebookAppId: "1196147987406568",
    googleClientId:
      "516750850752-mmvlvf1nvihqiqckfg67h3kqsodlvbfk.apps.googleusercontent.com"
  },
  // midTrans: {
  //   midTransClientKey: "SB-Mid-client-fKp6YjM93giMcxWu",
  //   midTransServerKey: "SB-Mid-server-PfVEeisG4PopQxDVIWFDy5nr",
  //   midTransBaseURL: "https://api.sandbox.midtrans.com"
  // },
  getAuthToken: () => {
    cookies.remove('twk_uuid_5f51c0684704467e89ec1397')
    let authentication_token = cookies.get("user_Token");
    if (authentication_token) {
      return authentication_token;
    }
  },
  calculateMinMaxSalePrice: SpreeProductPrices => {
    let minPrice,
      maxPrice,
      // salePrice,
      prices = [];
    SpreeProductPrices.map(price => {
      prices = [...prices, price.sale_price];
      return prices;
    });
    minPrice = Math.min(...prices);
    maxPrice = Math.max(...prices);
    // salePrice = currencySymbol + calculateCurrencyValue(minPrice, rate) + " - " + currencySymbol + " " + calculateCurrencyValue(maxPrice, rate);
    return [minPrice, maxPrice];
  },
  calculateCurrencyValue,
  addComaSeparator,
  getDateTimeTimezoneWise: (in_date, format) => {
    return momentTZ.tz(in_date, currentTimezone).format(format);
  },
  generatePermaLink: stringToConvert => {
    return _.toLower(_.trim(stringToConvert)).replace(/ /g, "-");
    //   return _.toLower(stringToConvert).replace(" ", "-");
  },
  loaderContext: loaderContext,
  scrollToTop: () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  },
  setTitleOfPage: (name) => {
    if (name) {
      let title = name + " - Aspenku";
      document.title = title
    }
    else {
      document.title = "Aspenku"
    }

  }
};
