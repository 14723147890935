import { USERPROFILE } from './typesOfActions';
export const getUserProfile = () => (dispatch, getState, Api) => {
  return Api.get("users/profile").then(response => {
    dispatch({
      type: USERPROFILE,
      user: response.data
    });
    return response;
  });
};

// export const userProfile = (userProfileData) => (dispatch, getState, Api) => {
//   dispatch({
//     type: USERPROFILE,
//     user: userProfileData
//   });
// };

export const editUserProfile = payload => (dispatch, getState, Api) => {
  return Api.put("users/profile", payload).then(response => {
    return response;
  });
};